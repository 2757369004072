// src/features/speaking/speakingSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface SpeakingData {
  text: string;         // 한국어 문장
  translation: string;  // 영어 번역
    audioUrl?: string;    // 오디오 URL
}

// 예: [theme][difficulty][day] -> SpeakingData
interface SpeakingState {
  [theme: string]: {
    [difficulty: string]: {
      [day: string]: SpeakingData;
    };
  };
}

// 초기 상태 예시
const initialState: SpeakingState = {
  'theme1': {
    'easy': {
      day1: {
        text: '잘 부탁드립니다.',
        translation: 'Nice to meet you. I look forward to working with you',
        audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/20241230_%E1%84%8C%E1%85%A1%E1%86%AF_%E1%84%87%E1%85%AE%E1%84%90%E1%85%A1%E1%86%A8%E1%84%83%E1%85%B3%E1%84%85%E1%85%B5%E1%86%B8%E1%84%82%E1%85%B5%E1%84%83%E1%85%A1..mp3',
      },
      day2: {
        text: '초급 - Day2 문장',
        translation: 'Translation for day2',
      },
    },
    '2.TOPIK - 중급': {
      day1: {
        text: '중급 - Day1 문장',
        translation: 'Translation for 중급 - Day1',
      },
    },
  },
  '비즈니스 한국어': {
    '1.TOPIK - 초급': {
      day1: {
        text: '비즈니스 한국어 / 초급 / Day1 문장',
        translation: 'Business Korean day1 translation',
      },
    },
  },
};

export const speakingSlice = createSlice({
  name: 'speaking',
  initialState,
  reducers: {
    // 필요 시 reducers 작성 (추가/수정/삭제 등)
  },
});

// Selector: theme, difficulty, day키를 받아 해당 데이터를 리턴
export const selectSpeakingByParams = (theme: string, difficulty: string, dayKey: string) => 
(state: any) => {
  return (
    state.speaking?.[theme]?.[difficulty]?.[dayKey] || {
      text: '',
      translation: '',
    }
  );
};

export default speakingSlice.reducer;
