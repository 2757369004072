import React, { useEffect, useState } from 'react';

function App() {
  // reCAPTCHA에서 받은 토큰 상태
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  // 검증(Assessment) 결과 상태
  const [recaptchaScore, setRecaptchaScore] = useState<number | null>(null);
  const [recaptchaMsg, setRecaptchaMsg] = useState<string>('');

  // 1) reCAPTCHA 스크립트 로드
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LfvSrAqAAAAAK4Zpto7EDvCfPytWHeLXxP_sbPU';
    script.async = true;
    document.head.appendChild(script);
  }, []);

  // 2) 토큰 발급(클릭 시)
  const handleGetToken = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if ((window as any).grecaptcha && (window as any).grecaptcha.enterprise) {
        (window as any).grecaptcha.enterprise.ready(async () => {
        try {
          const token = await (window as any).grecaptcha.enterprise.execute(
            '6LfvSrAqAAAAAK4Zpto7EDvCfPytWHeLXxP_sbPU', 
            { action: 'LOGIN' }
          );
          console.log('reCAPTCHA token:', token);
          setRecaptchaToken(token);
          setRecaptchaMsg('reCAPTCHA 토큰을 정상 발급받았습니다. 이제 검증을 진행해보세요.');
        } catch (err) {
          console.error(err);
          setRecaptchaMsg('reCAPTCHA 토큰 발급 중 오류가 발생했습니다.');
        }
      });
    } else {
      setRecaptchaMsg('reCAPTCHA 로딩 중... 잠시 후 다시 시도해주세요.');
    }
  };

  // 3) reCAPTCHA Enterprise에 직접 Assessment 생성 요청(검증)  
  //    원래는 "백엔드"에서 해야 하지만, 여기서는 데모로 "프런트엔드"에서 호출
  const handleVerifyToken = async () => {
    if (!recaptchaToken) {
      setRecaptchaMsg('먼저 reCAPTCHA 토큰을 발급받아주세요.');
      return;
    }

    try {
      // 3-1) API 요청 URL
      // - PROJECT_ID: Firebase/Google Cloud의 프로젝트 ID
      // - API_KEY   : reCAPTCHA Enterprise를 사용할 수 있는 유효한 API 키
      const PROJECT_ID = 'nissi-phone';
      const API_KEY = 'AIzaSyBvWDjMRVwT9Isl0e5xQA-lxNNLOqv4Bms';

      // 3-2) POST body
      // - "token": grecaptcha.enterprise.execute()로 발급받은 토큰
      // - "expectedAction": 위에서 { action: 'LOGIN' }으로 지정했던 값
      // - "siteKey": reCAPTCHA Enterprise 사이트 키
      const requestBody = {
        event: {
          token: recaptchaToken,
          expectedAction: 'LOGIN',
          siteKey: '6LfvSrAqAAAAAK4Zpto7EDvCfPytWHeLXxP_sbPU' // 예시
        }
      };

      // 3-3) Fetch 요청
      // - https://recaptchaenterprise.googleapis.com/v1/projects/[PROJECT_ID]/assessments?key=[API_KEY]
      const url = `https://recaptchaenterprise.googleapis.com/v1/projects/${PROJECT_ID}/assessments?key=${API_KEY}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('reCAPTCHA 검증 실패:', errorData);
        throw new Error(errorData.error?.message || 'Assessment API Error');
      }

      const result = await response.json();
      console.log('Assessment result:', result);

      // 3-4) result.tokenProperties.valid 가 true인지 확인
      //      score(0.0 ~ 1.0) 도 체크 가능
      if (!result.tokenProperties?.valid) {
        setRecaptchaMsg('reCAPTCHA 토큰이 유효하지 않습니다.');
        return;
      }

      // 정상 유효
      const score = result.riskAnalysis?.score || 0;
      setRecaptchaScore(score);
      setRecaptchaMsg('reCAPTCHA 검증 성공! score=' + score);
    } catch (error: any) {
      console.error('Assessment Error:', error);
      setRecaptchaMsg('reCAPTCHA 검증 중 오류 발생: ' + error.message);
    }
  };

  return (
    <div>
      <h1>reCAPTCHA Enterprise Test</h1>
      <p style={{ color: 'blue' }}>{recaptchaMsg}</p>

      {/* 1) 토큰 발급 버튼 */}
      <button onClick={handleGetToken}>
        Get reCAPTCHA token
      </button>

      {/* 2) 발급받은 토큰을 Assessment API로 검증 */}
      <button onClick={handleVerifyToken} style={{ marginLeft: '10px' }}>
        Verify reCAPTCHA token (Create Assessment)
      </button>

      {recaptchaScore !== null && (
        <p style={{ marginTop: '1rem' }}>
          <strong>Risk Score:</strong> {recaptchaScore}
        </p>
      )}
    </div>
  );
}

export default App;
