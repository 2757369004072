import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

const BottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      sx={{
        position: 'fixed',
        bottom: '16px',
        left: 0,
        right: 0,
        height: '10vh',
        background: 'transparent',
        zIndex: 1000,
        boxShadow: 'none',
      }}
    >
      <BottomNavigationAction
        label="Plan"
        value="/plan"
        icon={<EventNoteIcon sx={{ fontSize: 50 }} />} // 아이콘 크기 조정
        sx={{
          color: 'gray', // 기본(선택전) 아이콘 색상: 회색
          '&.Mui-selected': {
            color: 'black', // 선택된 아이콘 색상: 검은색
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '1rem', // 라벨 폰트 크기 조정 (필요 시)
          },
        }}
      />
      <BottomNavigationAction
        label="Dream"
        value="/dream"
        icon={<EmojiObjectsIcon sx={{ fontSize: 50 }} />}
        sx={{
          color: 'gray',
          '&.Mui-selected': {
            color: 'black',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '1rem',
          },
        }}
      />
      <BottomNavigationAction
        label="Home"
        value="/"
        icon={<HomeIcon sx={{ fontSize: 50 }} />}
        sx={{
          color: 'gray',
          '&.Mui-selected': {
            color: 'black',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '1rem',
          },
        }}
      />
      {/* <BottomNavigationAction
        label="Profile"
        value="/profile"
        icon={<AccountCircleIcon sx={{ fontSize: 50 }} />}
        sx={{
          color: 'gray',
          '&.Mui-selected': {
            color: 'black',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '1rem',
          },
        }}
      /> */}
      <BottomNavigationAction
        label="Setting"
        value="/setting"
        icon={<SettingsIcon sx={{ fontSize: 50 }} />}
        sx={{
          color: 'gray',
          '&.Mui-selected': {
            color: 'black',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '1rem',
          },
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNav;

