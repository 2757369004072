import React, { useState } from 'react';
import { signUp } from '../../AuthService/AuthService';
import { useNavigate, Link } from 'react-router-dom';

// 1) react-phone-number-input 라이브러리
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
// 2) 스타일 (css)
import 'react-phone-number-input/style.css';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // 여기에 국제번호 형식이 들어올 것
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // 닉네임, 비밀번호, 생년월일 유효성 등 기존 로직 유지
  const validateNickname = (nickname: string) => {
    const nicknameRegex = /^[a-zA-Z0-9가-힣]{1,10}$/;
    return nicknameRegex.test(nickname);
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateDateOfBirth = (dateOfBirth: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(dateOfBirth);
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // 1) 닉네임
    if (!validateNickname(nickname)) {
      setError('닉네임은 한글, 영문, 숫자만 가능하며 10자 이내여야 합니다.');
      return;
    }

    // 2) 비밀번호
    if (!validatePassword(password)) {
      setError('비밀번호는 최소 8자, 영문 대소문자, 숫자, 특수기호를 포함해야 합니다.');
      return;
    }

    if (password !== confirmPassword) {
      setError('비밀번호와 비밀번호 확인이 일치하지 않습니다.');
      return;
    }

    // 3) 전화번호 (react-phone-number-input & isValidPhoneNumber 사용)
    //    phoneNumber가 ''(빈값) 이거나 유효하지 않으면 에러
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      setError('유효한 전화번호를 입력해주세요. (국가코드 포함)');
      return;
    }

    // 4) 생년월일
    if (!validateDateOfBirth(dateOfBirth)) {
      setError('생년월일은 YYYY-MM-DD 형식으로 입력해주세요.');
      return;
    }

    setIsLoading(true);
    try {
      // 회원가입 (Cognito 등)
      // phoneNumber는 +82xxxx... 이런 E.164 형식이 들어올 것
      const result = await signUp(email, password, nickname, phoneNumber, dateOfBirth);
      console.log('회원가입 성공:', result);
      navigate('/confirm-signup', { state: { email } });
    } catch (error: any) {
      console.error('회원가입 실패:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(JSON.stringify(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSignUp} style={styles.form}>
        <h2>회원가입</h2>
        {error && <p style={styles.error}>{error}</p>}

        {/* 이메일 */}
        <div style={styles.inputGroup}>
          <label>이메일:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
            autoComplete="username"
          />
        </div>

        {/* 비밀번호 */}
        <div style={styles.inputGroup}>
          <label>비밀번호:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="new-password"
            style={styles.input}
          />
        </div>

        {/* 비밀번호 확인 */}
        <div style={styles.inputGroup}>
          <label>비밀번호 확인:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            style={styles.input}
          />
        </div>

        {/* 닉네임 */}
        <div style={styles.inputGroup}>
          <label>닉네임:</label>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            required
            style={styles.input}
          />
        </div>

        {/* 전화번호 (react-phone-number-input) */}
        <div style={styles.inputGroup}>
          <label>전화번호:</label>
          <PhoneInput
            defaultCountry="KR"      // 기본 국가
            placeholder="전화번호 입력 (ex: +82 10-1234-5678)"
            value={phoneNumber}      // 현재 상태
            onChange={(value) => setPhoneNumber(value || '')}
            international           // 국제표기
            withCountryCallingCode  // 국가코드 표시
          />
        </div>

        {/* 생년월일 */}
        <div style={styles.inputGroup}>
          <label>생년월일:</label>
          <input
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            required
            style={styles.input}
          />
        </div>

        <button type="submit" style={styles.button} disabled={isLoading}>
          {isLoading ? '가입 중...' : '가입하기'}
        </button>
        <p style={styles.text}>
          이미 계정이 있으신가요? <Link to="/signin">로그인</Link>
        </p>
      </form>
    </div>
  );
};

export default SignUp;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
  form: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  inputGroup: {
    marginBottom: '1rem',
  },
  input: {
    width: '100%',
    padding: '0.5rem',
    marginTop: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    width: '100%',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#28a745',
    color: '#fff',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  text: {
    marginTop: '1rem',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    marginBottom: '1rem',
    textAlign: 'center',
  },
};
