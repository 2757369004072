import React, { useState } from 'react';
import { confirmSignUp } from '../../AuthService/AuthService';
import { useNavigate, useLocation, Link } from 'react-router-dom';

interface LocationState {
  email: string;
}

const ConfirmSignUp: React.FC = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  if (!state || !state.email) {
    navigate('/signin');
    return null;
  }

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      await confirmSignUp(state.email, code);
      alert('이메일 확인이 완료되었습니다. 로그인 페이지로 이동합니다.');
      navigate('/signin');
    } catch (error: any) {
      console.error('확인 실패:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(JSON.stringify(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleConfirm} style={styles.form}>
        <h2 style={styles.title}>이메일 확인</h2>
        <h3 style={styles.subtitle}>
          회원가입 시 발송된<br />인증 코드를 입력해주세요.
        </h3>

        {error && <p style={styles.error}>{error}</p>}

        <div style={styles.inputGroup}>
          <label style={styles.label}>인증 코드</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            style={styles.input}
            placeholder="6자리 인증 코드"
          />
        </div>

        <button type="submit" style={styles.button} disabled={isLoading}>
          {isLoading ? '확인 중...' : '확인하기'}
        </button>

        <p style={styles.text}>
          이미 인증을 완료하셨나요?{' '}
          <Link to="/signin" style={styles.link}>
            로그인
          </Link>
        </p>
      </form>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  /* 페이지 전체 컨테이너 (SignIn과 톤 통일) */
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fff',
    padding: '2rem',
  },
  /* 폼 영역 */
  form: {
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#fff',
  },
  /* 제목 */
  title: {
    marginBottom: '0.5rem',
    fontSize: '1.8rem',
    textAlign: 'center',
  },
  /* 부제목 */
  subtitle: {
    marginBottom: '1.5rem',
    fontSize: '1rem',
    textAlign: 'center',
    color: '#555',
    lineHeight: 1.4,
  },
  /* 에러 메시지 */
  error: {
    color: 'red',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  /* 입력 그룹 컨테이너 */
  inputGroup: {
    marginBottom: '1rem',
  },
  /* 라벨 */
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    fontSize: '0.9rem',
    color: '#333',
  },
  /* 텍스트 입력 */
  input: {
    width: '100%',
    padding: '1rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    boxSizing: 'border-box',
  },
  /* 확인하기 버튼 */
  button: {
    width: '100%',
    padding: '1rem',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#d3a264', // SignIn과 동일한 브랜드 톤
    color: '#fff',
    fontSize: '1.2rem',
    cursor: 'pointer',
    marginTop: '1rem',
  },
  /* 하단 안내 텍스트 */
  text: {
    marginTop: '1.5rem',
    textAlign: 'center',
    fontSize: '0.9rem',
    color: '#555',
  },
  /* 링크 스타일 */
  link: {
    textDecoration: 'none',
    color: '#d3a264', // 버튼 색상과 톤 매칭
    fontWeight: 'bold',
  },
};

export default ConfirmSignUp;
