// profileSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
  name: string;
  nickname: string;
  introduction: string;
  image: string;

  // 추가: Dream 페이지용 이미지(최대 7개)
  dream1: string;
  dream2: string;
  dream3: string;
  dream4: string;
  dream5: string;
  dream6: string;
  dream7: string;
  dreamtext : string;
}

const initialState: ProfileState = {
  name: '',
  nickname: '',
  introduction: '',
  image: '/path/to/profile.jpg',

  // Dream 이미지 초기값 (없으면 빈 문자열)
  dream1: '',
  dream2: '',
  dream3: '',
  dream4: '',
  dream5: '',
  dream6: '',
  dream7: '',
  dreamtext: ''
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileData(state, action: PayloadAction<ProfileState>) {
      state.name = action.payload.name;
      state.nickname = action.payload.nickname;
      state.introduction = action.payload.introduction;
      state.image = action.payload.image;
      state.dream1 = action.payload.dream1;
      state.dream2 = action.payload.dream2;
      state.dream3 = action.payload.dream3;
      state.dream4 = action.payload.dream4;
      state.dream5 = action.payload.dream5;
      state.dream6 = action.payload.dream6;
      state.dream7 = action.payload.dream7;
      state.dreamtext = action.payload.dreamtext;
    },
    updateProfileImage(state, action: PayloadAction<string>) {
      // 프로필 페이지에서 사용하는 단일 프로필 사진
      state.image = action.payload;
    },

    // [추가] 각 dream 박스 이미지를 업데이트하는 액션
    updateDreamImage(state, action: PayloadAction<{ index: number; url: string }>) {
      const { index, url } = action.payload;
      switch (index) {
        case 0:
          state.dream1 = url;
          break;
        case 1:
          state.dream2 = url;
          break;
        case 2:
          state.dream3 = url;
          break;
        case 3:
          state.dream4 = url;
          break;
        case 4:
          state.dream5 = url;
          break;
        case 5:
          state.dream6 = url;
          break;
        case 6:
          state.dream7 = url;
          break;
        default:
          break;
      }
    },

    updateDreamText(state, action: PayloadAction<string>) {
      state.dreamtext = action.payload;
    }
  },
});

export const { 
  setProfileData, 
  updateProfileImage, 
  updateDreamImage 
} = profileSlice.actions;

export default profileSlice.reducer;
