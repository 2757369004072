import * as React from "react";
const PlanShadow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={62}
    height={15}
    viewBox="0 0 62 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={31} cy={7.5} rx={31} ry={7.5} fill="black" fillOpacity={0.3} />
  </svg>
);
export default PlanShadow;