import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  keyframes,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// 새로 추가할 아이콘들
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MicNoneIcon from '@mui/icons-material/MicNone';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';

// Capacitor plugins for iOS support
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { Capacitor } from '@capacitor/core';

// Redux - 메시지 데이터 구조 사용
import { selectMessagesByParams } from '../../store/messagesSlice';

// TypeScript interfaces - Capacitor 플러그인에서 직접 타입 가져오기
import type { 
  GenericResponse 
} from 'capacitor-voice-recorder';

// 타입 별칭으로 간단하게 정의
type RecordingPermissionResult = GenericResponse;

// 타입 정의
interface Message {
  text: string;
  textEng?: string;
  highlight: string;
  highlightEng?: string; // 추가: 영어 하이라이트 부분
  background: string;
  textColor: string;
  audioUrl: string;
}

// 파형 애니메이션 정의
const waveAnimation = keyframes`
  0% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(1.4);
  }
  100% {
    transform: scaleY(0.6);
  }
`;

const Speaking: React.FC = () => {
  // URL 파라미터로 dayId ("1", "2", "3" 등)
  const params = useParams<{ dayId?: string }>();
  const dayId = params.dayId || "1"; // 기본값 설정
  const location = useLocation();
  
  // URL 쿼리 파라미터 추출
  const queryParams = new URLSearchParams(location.search);
  
  // 쿼리 파라미터로부터 theme, category, difficulty를 가져오거나 기본값 설정
  const themeParam = queryParams.get('theme') || '일상';
  const categoryParam = queryParams.get('category') || '친구';
  const difficultyParam = queryParams.get('difficulty') || '초급';

  const dayKey = `Day${dayId}`;
  
  // 새로운 데이터 구조에 맞게 selectMessagesByParams 호출
  const messages = useSelector(selectMessagesByParams(
    themeParam, 
    categoryParam, 
    difficultyParam, 
    dayKey
  )) as Message[];

  // 하이라이트가 있는 유효한 메시지만 필터링
  const validMessages = useMemo(() => {
    return messages.filter(msg => msg.highlight);
  }, [messages]);
  
  // 현재 메시지 인덱스 관리 (유효한 메시지 배열 기준)
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  
  // 다음 유효한 메시지 인덱스를 찾는 함수
  const findNextValidIndex = (currentValidIndex: number): number => {
    const nextValidIndex = currentValidIndex + 1;
    return nextValidIndex < validMessages.length ? nextValidIndex : -1;
  };
  
  // 현재 메시지 (유효한 메시지 배열 기준)
  const speakingData = validMessages[currentIndex] || {} as Message;

  // 다음 메시지로 이동하는 함수 (유효한 메시지 배열 기준)
  const handleNextMessage = (): void => {
    const nextValidIndex = findNextValidIndex(currentIndex);
    
    if (nextValidIndex !== -1) {
      // 유효한 다음 메시지가 있으면 이동
      setCurrentIndex(nextValidIndex);
      setIsRecorded(false);
      setIsRecording(false);
      setShowRecordingReady(false);
      setAudioChunks([]);
    } else {
      // 더 이상 유효한 메시지가 없으면 writing 페이지로 이동하는 로직은 
      // 기존 hasNextMessage 판단에 의해 처리됨
    }
  };

  // 다음 하이라이트 문장이 있는지 확인 (유효한 메시지 배열 기준)
  const hasNextMessage = (): boolean => {
    return findNextValidIndex(currentIndex) !== -1;
  };

  // ========== 오디오 재생 관련 ==========
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handlePlay = (url: string): void => {
    if (isPlaying) return;
    const audio = new Audio(url);
    audioRef.current = audio;
    setIsPlaying(true);

    audio.play().catch((error) => {
      console.error('Audio play error:', error);
      setIsPlaying(false);
    });

    audio.addEventListener('ended', () => {
      setIsPlaying(false);
    });
  };

  // ========== 녹음 관련 ==========
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isRecorded, setIsRecorded] = useState<boolean>(false);
  
  // 바텀 시트 표시 상태 (녹음 준비 상태)
  const [showRecordingReady, setShowRecordingReady] = useState<boolean>(false);
  
  // 녹음 준비 상태 표시
  const handleShowRecordingReady = (): void => {
    setShowRecordingReady(true);
  };

  // 녹음 시작
  const handleRecordStart = useCallback(async (): Promise<void> => {
    console.log('Start recording...');
    setIsRecording(true);
    setIsRecorded(false);

    try {
      // iOS에서는 Capacitor의 VoiceRecorder 사용
      if (Capacitor.getPlatform() === 'ios') {
        // 권한 확인
        const { value }: RecordingPermissionResult = await VoiceRecorder.hasAudioRecordingPermission();
        
        if (!value) {
          const { value: permissionValue }: RecordingPermissionResult = 
            await VoiceRecorder.requestAudioRecordingPermission();
          if (!permissionValue) {
            throw new Error('Permission denied');
          }
        }
        
        await VoiceRecorder.startRecording();
      } else {
        // 웹에서는 기존 MediaRecorder API 사용
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        
        const chunks: Blob[] = [];
        recorder.ondataavailable = (e: BlobEvent) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };

        recorder.onstop = () => {
          setAudioChunks(chunks);
        };

        recorder.start();
      }
    } catch (err) {
      console.error('Error accessing microphone', err);
      setIsRecording(false);
    }
  }, []);

  // 녹음 중지
  const handleRecordStop = useCallback(async (): Promise<void> => {
    console.log('Stop recording.');
    setIsRecording(false);
    
    try {
      if (Capacitor.getPlatform() === 'ios') {
        // iOS에서는 Capacitor VoiceRecorder 사용
        const recordingResult = await VoiceRecorder.stopRecording();
        setIsRecorded(true);
        
        // recordDataBase64가 undefined인지 확인
        if (!recordingResult.value.recordDataBase64) {
          console.error('Recording data is empty');
          return;
        }
        
        // base64 데이터를 Blob으로 변환
        const base64Data: string = recordingResult.value.recordDataBase64;
        const mimeType: string = recordingResult.value.mimeType || 'audio/m4a';
        
        const byteCharacters: string = atob(base64Data);
        const byteArrays: Uint8Array[] = [];
        
        for (let i = 0; i < byteCharacters.length; i += 512) {
          const slice: string = byteCharacters.slice(i, i + 512);
          
          const byteNumbers: number[] = new Array(slice.length);
          for (let j = 0; j < slice.length; j++) {
            byteNumbers[j] = slice.charCodeAt(j);
          }
          
          const byteArray: Uint8Array = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        
        const blob: Blob = new Blob(byteArrays, { type: mimeType });
        setAudioChunks([blob]);
      } else {
        // 웹에서는 MediaRecorder 사용
        setIsRecorded(true);
      }
    } catch (err) {
      console.error('Error stopping recording', err);
    }
  }, []);

  // 토글
  const handleToggleRecord = useCallback((): void => {
    if (isRecording) {
      handleRecordStop();
    } else {
      handleRecordStart();
    }
  }, [isRecording, handleRecordStop, handleRecordStart]);

  // 녹음 파일 재생
  const handlePlayRecorded = useCallback((): void => {
    if (audioChunks.length === 0) return;
    
    const blob: Blob = audioChunks[0];
    const audioURL: string = URL.createObjectURL(blob);
    
    const recordedAudio: HTMLAudioElement = new Audio(audioURL);
    recordedAudio.play().catch(console.error);
  }, [audioChunks]);

  // "다시 녹음" 버튼
  const handleRecordAgain = useCallback((): void => {
    // 바로 녹음 시작이 아닌, 녹음 준비 상태로 전환
    setIsRecorded(false);
    setShowRecordingReady(true);
  }, []);

  const backPage = (): void => {
    window.history.back();
  }

  // useEffect는 더 이상 필요없음 - validMessages가 이미 하이라이트가 있는 메시지만 포함

  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingTop: '55px',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            height: 'calc(100vh - 155px)',
            overflowY: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8,
            pb: 8,
          }}
        >
          {/* 상단 영역 */}
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
          <Box onClick={backPage} sx={{ cursor: 'pointer', color: 'black' }}>
              <ArrowBackIosNewIcon sx={{ fontSize: 24 }} />
            </Box>

            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              DAY {dayId}
            </Typography>

            <NotificationsNoneIcon sx={{ fontSize: 24 }} />
          </Box>

          {/* 학습 카드 - 하이라이트가 있는 경우에만 표시 */}
          {speakingData.highlight && (
            <Box
              sx={{
                mt: 5,
                backgroundColor: '#FFFFFF',
                borderRadius: '24px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                width: '92%',
                maxWidth: 400,
                pt: '60px',
                pb:'60px',
                pr: '20px',
                pl: '20px',
                textAlign: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: '30px' }}>
                "{speakingData.highlight}"를 읽어보세요.
                <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                 {currentIndex + 1}/{validMessages.length}
                </Typography>
              </Typography>

              <Box sx={{ mb:'30px'  }}>
                <VolumeUpIcon
                  onClick={() => speakingData.audioUrl ? handlePlay(speakingData.audioUrl) : undefined}
                  sx={{ fontSize: 40, color: '#666', cursor: 'pointer' }}
                />
              </Box>

              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: '30px'  }}>
                "{speakingData.highlight}"
              </Typography>

              {/* 영어 하이라이트 부분 */}
              <Typography variant="body1" sx={{ color: '#666', mb: '30px'  }}>
                = {speakingData.highlightEng || ''}
              </Typography>

              {/* 녹음 시작 버튼 (처음 상태에서만) */}
              {!isRecording && !isRecorded && (
                <Box >
                  <Button
                    variant="outlined"
                    startIcon={<MicNoneIcon />}
                    onClick={handleShowRecordingReady}
                    sx={{
                      backgroundColor: '#FFF',
                      borderColor: '#BFBFBF',
                      borderRadius: '30px',
                      color: '#666',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: '#F9F8FD',
                        borderColor: '#BFBFBF',
                      },
                    }}
                  >
                    녹음 시작
                  </Button>
                </Box>
              )}
            </Box>
          )}

          {/* 하단 NEXT 버튼 */}
          <Box
            sx={{
              position: 'absolute',
              bottom: '40px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {hasNextMessage() ? (
              // 다음 메시지가 있으면 다음 메시지로 이동
              <Button
                variant="contained"
                onClick={handleNextMessage}
                sx={{
                  backgroundColor: '#9D76DD',
                  borderRadius: '40px',
                  padding: '10px 20px',
                  color: '#FFF',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  '&:hover': {
                    backgroundColor: '#B28FF0',
                  },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                NEXT
              </Button>
            ) : (
              // 다음 메시지가 없으면 writing 페이지로 이동
              <Link
                to={`/plan/writing/${dayId}?theme=${encodeURIComponent(
                  themeParam
                )}&category=${encodeURIComponent(
                  categoryParam
                )}&difficulty=${encodeURIComponent(difficultyParam)}`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#9D76DD',
                    borderRadius: '40px',
                    padding: '10px 20px',
                    color: '#FFF',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    '&:hover': {
                      backgroundColor: '#B28FF0',
                    },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                  NEXT
                </Button>
              </Link>
            )}
          </Box>
        </Container>

        {/* ▼ 바텀 시트 */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#FFFFFF',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
            height: '220px', // 원하는 높이
            transition: 'transform 0.3s ease',
            // 녹음 관련 상태에 따라 표시
            transform: (showRecordingReady || isRecording || isRecorded)
              ? 'translateY(0%)'
              : 'translateY(100%)',
            zIndex: 10000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: 2, // 좌우 패딩 약간
          }}
        >
          {/* 1) 녹음 준비 상태 */}
          {showRecordingReady && !isRecording && !isRecorded && (
              <Box
                sx={{
                  width: '90%',
                  height: '60px',
                  borderRadius: '8px',
                  position: 'relative',
                  mb: 10,
                }}
              >
                <Typography 
                  variant="caption" 
                  sx={{ 
                    position: 'absolute',
                    top: -25,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: 'text.secondary'
                  }}
                >
                  {currentIndex + 1}/{validMessages.length}
                </Typography>
                {/* 왼쪽 점선 (dotted) */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    width: '60px',
                    height: '0px',
                    borderTop: '6px dotted #000',
                  }}
                />
                {/* 오른쪽 점선 (dotted) */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                    width: '60px',
                    height: '0px',
                    borderTop: '6px dotted #000',
                  }}
                />
           
                {/* 중앙 정적 막대들 (애니메이션 없음) */}
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%',
                    gap: '6px',
                  }}
                >
                  {[15, 20, 15, 25, 15, 12, 15, 20, 15, 12, 15, 12, 15].map(
                    (barHeight, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          width: '6px',
                          height: `${barHeight}px`,
                          backgroundColor: '#666', 
                          transformOrigin: 'bottom',
                        }}
                      />
                    )
                  )}
                </Box>
           
                {/* 녹음 시작 버튼 (빨간색) */}
                <IconButton
                  onClick={handleToggleRecord}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: '#F44336',
                    border: '3px solid #FFFFFF',
                    mt: 10,
                    boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                    '&:hover': {
                      backgroundColor: '#D32F2F',
                    },
                  }}
                />
              </Box>
          )}

          {/* 2) 녹음 중인 상태 */}
          {isRecording && !isRecorded && (
              <Box
                sx={{
                  width: '90%',
                  height: '60px',
                  borderRadius: '8px',
                  position: 'relative',
                  mb: 10,
                }}
              >
                <Typography 
                  variant="caption" 
                  sx={{ 
                    position: 'absolute',
                    top: -25,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: 'text.secondary'
                  }}
                >
                   {currentIndex + 1}/{messages.length}
                </Typography>
                {/* 왼쪽 점선 (dotted) */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    width: '60px',        // 점선의 길이
                    height: '0px',        // 세로 높이는 0, 'border'만 표시
                    borderTop: '6px dotted #000', // 두께=6px, dotted, 색=검정
                  }}
                />
                {/* 오른쪽 점선 (dotted) */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                    width: '60px',
                    height: '0px',
                    borderTop: '6px dotted #000',
                  }}
                />
           

                {/* 중앙 파형 (애니메이션 막대들) */}
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%',
                    gap: '6px',
                  }}
                >
                  {[20, 35, 25, 40, 25, 18, 35, 50, 30, 20, 45, 22, 38].map(
                    (barHeight, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          width: '6px',
                          height: `${barHeight}px`,
                          backgroundColor: '#000',
                          transformOrigin: 'bottom',
                          animation: `${waveAnimation} 1.5s infinite ease-in-out`,
                          animationDelay: `${idx * 0.1}s`,
                        }}
                      />
                    )
                  )}
                </Box>
           

              {/* 빨간 버튼 (녹음 중지) */}
              <IconButton
                onClick={handleToggleRecord}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: '#F44336',
                  border: '3px solid #FFFFFF',
                  mt: 10,
                  boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                  '&:hover': {
                    backgroundColor: '#D32F2F',
                  },
                }}
              >
                {/* 원하는 Stop 아이콘이 있으면 추가 가능 */}
              </IconButton>
            </Box>
          )}

          {!isRecording && isRecorded && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'text.secondary',
                  mb: 1
                }}
              >
              {currentIndex + 1}/{validMessages.length}
              </Typography>
              
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {/* 재생 (Play) 버튼 */}
                <IconButton
                  onClick={handlePlayRecorded}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: '#FFF',
                    color: '#000',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.15)',
                  }}
                >
                  <PlayArrowIcon sx={{ fontSize: 28 }} />
                </IconButton>

                {/* 다시 녹음 (Replay) 버튼 */}
                <IconButton
                  onClick={handleRecordAgain}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: '#FFF',
                    color: '#000',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.15)',
                  }}
                >
                  <ReplayIcon sx={{ fontSize: 26 }} />
                </IconButton>

                {hasNextMessage() ? (
                  // 다음 메시지로 이동하는 버튼
                  <Button
                    onClick={handleNextMessage}
                    variant="contained"
                    sx={{
                      backgroundColor: '#9D76DD',
                      borderRadius: '40px',
                      padding: '10px 20px',
                      color: '#FFF',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      '&:hover': {
                        backgroundColor: '#B28FF0',
                      },
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                    NEXT
                  </Button>
                ) : (
                  // Writing 페이지로 이동하는 버튼
                  <Link
                    to={`/plan/writing/${dayId}?theme=${encodeURIComponent(
                      themeParam
                    )}&category=${encodeURIComponent(
                      categoryParam
                    )}&difficulty=${encodeURIComponent(difficultyParam)}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#9D76DD',
                        borderRadius: '40px',
                        padding: '10px 20px',
                        color: '#FFF',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        '&:hover': {
                          backgroundColor: '#B28FF0',
                        },
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                      NEXT
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default Speaking;