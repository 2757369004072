import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

// Stripe 공개키를 넣어주세요.
const stripePromise = loadStripe('your-stripe-public-key');

const Payment = () => {
  const handleCardPayment = async () => {
    console.log('카드결제 버튼 클릭');
    try {
      // 서버리스 함수 호출 (배포 시 자동으로 /api 경로 사용)
      const response = await axios.post('/api/create-subscription-session', {
        // 추가 데이터가 필요하다면 여기에 전달 (예: 고객 정보 등)
      });
      
      const sessionId = response.data.sessionId;
      // Stripe Checkout 페이지로 리다이렉트
      const stripe = await stripePromise;
      if(!stripe) {
        console.error('Stripe 객체를 불러오는데 실패했습니다.');
        return;
      }
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe 리다이렉트 에러:', error);
      }
    } catch (err) {
      console.error('구독 결제 API 호출 중 에러 발생:', err);
    }
  };

  const handlePaypalPayment = () => {
    console.log('페이팔 버튼 클릭');
    // 페이팔 결제 로직 추가
  };

  const handleApplePay = () => {
    console.log('애플페이 버튼 클릭');
    // 애플페이 결제 로직 추가
  };

  return (
    <div>
      <h1>Payment</h1>
      <p>Payment page</p>
      <button onClick={handleCardPayment}>카드결제</button>
      <button onClick={handlePaypalPayment}>페이팔</button>
      <button onClick={handleApplePay}>애플페이</button>
    </div>
  );
};

export default Payment;
