import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { poolData } from './awsConfig';

const userPool = new CognitoUserPool(poolData);

export const signUp = (
  email: string,
  password: string,
  nickname: string,
  phoneNumber: string,
  dateOfBirth: string,
  lineSub?: string // LINE 가입 시 sub 값을 전달받기 위한 optional 매개변수
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const attributeList: CognitoUserAttribute[] = [
      new CognitoUserAttribute({
        Name: 'custom:nickname',
        Value: nickname,
      }),
      new CognitoUserAttribute({
        Name: 'custom:phone_number',
        Value: phoneNumber,
      }),
      new CognitoUserAttribute({
        Name: 'custom:birthdate',
        Value: dateOfBirth,
      }),
    ];

    // lineSub 값이 있다면 custom:linesub 속성으로 추가
    if (lineSub) {
      attributeList.push(
        new CognitoUserAttribute({
          Name: 'custom:linesub',
          Value: lineSub,
        })
      );
    }

    userPool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export const signIn = (email: string, password: string): Promise<any> => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const signOut = (): void => {
  const user = userPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};

export const getCurrentUser = (): CognitoUser | null => {
  return userPool.getCurrentUser();
};

export const confirmSignUp = (email: string, code: string): Promise<any> => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

// **New Function to Get User Attributes**
export const getUserAttributes = (): Promise<{ [key: string]: string }> => {
  const user = userPool.getCurrentUser();

  if (!user) {
    return Promise.reject(new Error('현재 로그인된 사용자가 없습니다.'));
  }

  return new Promise((resolve, reject) => {
    user.getSession((err: any, session: { isValid: () => any; }) => {
      if (err) {
        reject(err);
      } else if (!session.isValid()) {
        reject(new Error('세션이 유효하지 않습니다.'));
      } else {
        user.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else if (!attributes) { // attributes가 undefined인지 확인
            reject(new Error('사용자 속성을 가져올 수 없습니다.'));
          } else {
            const attrs: { [key: string]: string } = {};
            attributes.forEach((attribute) => {
              attrs[attribute.getName()] = attribute.getValue();
            });
            resolve(attrs);
          }
        });
      }
    });
  });
};

// **New Function to Update User Attributes**
export const updateUserAttributes = (attributes: { [key: string]: string }): Promise<void> => {
  const user = userPool.getCurrentUser();

  if (!user) {
    return Promise.reject(new Error('No user is currently logged in.'));
  }

  return new Promise((resolve, reject) => {
    user.getSession((err: any, session: { isValid: () => any; }) => {
      if (err) {
        reject(err);
      } else if (!session.isValid()) {
        reject(new Error('Session is invalid.'));
      } else {
        const attributeList: CognitoUserAttribute[] = Object.keys(attributes).map((key) => {
          return new CognitoUserAttribute({
            Name: key,
            Value: attributes[key],
          });
        });

        user.updateAttributes(attributeList, (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      }
    });
  });
};

// AuthService.ts

/**
 * 1) 비밀번호 재설정 요청 (Forgot Password)
 *    - Cognito에 이메일을 전달하면, 해당 이메일로 인증 코드 전송
 */
export const forgotPassword = (email: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.forgotPassword({
      onSuccess: () => {
        // 인증 코드 발송 성공
        resolve();
      },
      onFailure: (err) => {
        // 인증 코드 발송 실패
        reject(err);
      },
    });
  });
};

/**
 * 2) 새 비밀번호 설정 (Confirm Password)
 *    - 이메일, 인증 코드, 새 비밀번호 전달
 */
export const confirmNewPassword = (
  email: string,
  code: string,
  newPassword: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    // 인증 코드 + 새 비밀번호 전달
    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};
