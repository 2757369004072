import { useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Paper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AppVersion = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const appVersion = '1.0.0'; // 앱 버전 정보

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '55px',
      }}
    >
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            앱버전 정보
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 2, // 상단 여백 추가
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: '90%',
            padding: 2,
            backgroundColor: 'white',
          }}
        >
          <Typography variant="h5" align="center">
            현재 앱 버전은 {appVersion} 입니다.
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default AppVersion;

