import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Button,
  Modal,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import emailjs from 'emailjs-com';

const CustomerService = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  // 상태 변수 설정
  const [companyName, setCompanyName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [collaborationDetails, setCollaborationDetails] = useState('');

  const [open, setOpen] = useState(false);

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const templateParams = {
      company_name: companyName,
      contact_number: contactNumber,
      email_address: emailAddress,
      collaboration_details: collaborationDetails,
      to_email: 'nissiofficial1225@gmail.com', // 받을 이메일 주소
    };

    emailjs
      .send(
        'service_57z79mj', // EmailJS에서 받은 Service ID로 대체하세요
        'template_f0bu1o4', // EmailJS에서 생성한 Template ID로 대체하세요
        templateParams,
        'j3kL2EKT8iRhlL1Et' // EmailJS에서 받은 Public Key로 대체하세요
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setOpen(true);
          // 폼 초기화
          setCompanyName('');
          setContactNumber('');
          setEmailAddress('');
          setCollaborationDetails('');
        },
        (err) => {
          console.log('FAILED...', err);
          alert('제출에 실패했습니다. 다시 시도해주세요.');
        }
      );
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '55px',
        alignItems: 'center',
      }}
    >
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            고객센터 문의
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '90%',
          marginTop: '20px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
        }}
      >
        <TextField
          fullWidth
          label="이름"
          variant="outlined"
          margin="normal"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          fullWidth
          label="연락처"
          variant="outlined"
          margin="normal"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
        />
        <TextField
          fullWidth
          label="이메일 주소"
          variant="outlined"
          margin="normal"
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
        />
        <TextField
          fullWidth
          label="문의 내용"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          value={collaborationDetails}
          onChange={(e) => setCollaborationDetails(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            marginTop: '20px',
            backgroundColor: '#D8A45F',
            '&:hover': {
              backgroundColor: '#b0844b',
            },
          }}
        >
          제출
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            문의 내용이 제출되었습니다!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, backgroundColor: '#D8A45F' }}
            onClick={() => setOpen(false)}
          >
            확인
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomerService;

