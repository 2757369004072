// src/pages/Dream.tsx

import React, { useEffect, useState, useRef } from 'react';
import Layout from '../components/Layout';
import {
  Box,
  Container,
  Typography,
  IconButton,
  Modal,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateDreamImage } from '../store/profileSlice';

// Cognito 연동 로직 (이미 구현되어 있다고 가정)
import { getUserAttributes, updateUserAttributes } from '../AuthService/AuthService';

// Cloudinary 환경 변수
const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || '';

// -------------------------
// 12글자 단위로 줄바꿈하는 헬퍼 함수
// -------------------------
function wrapText(str: string, chunkSize = 12): string {
  if (!str) return '';
  // 최대 chunkSize글자씩 정규식 매칭
  const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
  const matched = str.match(regex);
  // 매칭된 배열을 '\n'으로 join
  return matched ? matched.join('\n') : str;
}

const Dream: React.FC = () => {
  const dispatch = useDispatch();

  // --------------------------
  // 1) Redux에서 이미지(dream1~7) 가져오기
  // --------------------------
  const {
    dream1,
    dream2,
    dream3,
    dream4,
    dream5,
    dream6,
    dream7,
  } = useSelector((state: RootState) => state.profile);

  // 배열로 관리 (인덱스 0 ~ 6)
  const dreamImages = [dream1, dream2, dream3, dream4, dream5, dream6, dream7];

  // --------------------------
  // 2) 7개의 텍스트( custom:dreamtext ) -> 로컬 state
  // --------------------------
  const [dreamTexts, setDreamTexts] = useState<string[]>(Array(7).fill(''));

  // --------------------------
  // 3) 컴포넌트 마운트 시 Cognito에서 속성 불러오기
  // --------------------------
  useEffect(() => {
    const fetchDreamData = async () => {
      try {
        const attributes = await getUserAttributes();

        // (A) 이미지: custom:dream1 ~ custom:dream7
        if (attributes['custom:dream1']) dispatch(updateDreamImage({ index: 0, url: attributes['custom:dream1'] }));
        if (attributes['custom:dream2']) dispatch(updateDreamImage({ index: 1, url: attributes['custom:dream2'] }));
        if (attributes['custom:dream3']) dispatch(updateDreamImage({ index: 2, url: attributes['custom:dream3'] }));
        if (attributes['custom:dream4']) dispatch(updateDreamImage({ index: 3, url: attributes['custom:dream4'] }));
        if (attributes['custom:dream5']) dispatch(updateDreamImage({ index: 4, url: attributes['custom:dream5'] }));
        if (attributes['custom:dream6']) dispatch(updateDreamImage({ index: 5, url: attributes['custom:dream6'] }));
        if (attributes['custom:dream7']) dispatch(updateDreamImage({ index: 6, url: attributes['custom:dream7'] }));

        const existingTextString = attributes['custom:dreamtext'] || '';
        let textArray = existingTextString.split('@#$'); 

        // 길이가 7이 안 되면 뒷부분을 빈 문자열로 채움
        while (textArray.length < 7) {
          textArray.push('');
        }
        setDreamTexts(textArray);
      } catch (error) {
        console.error('Cognito 사용자 속성 가져오기 오류:', error);
      }
    };

    fetchDreamData();
  }, [dispatch]);

  // --------------------------
  // (A) 모달 제어
  // --------------------------
  const [openModal, setOpenModal] = useState(false);         // 첫 번째 모달
  const [openSecondModal, setOpenSecondModal] = useState(false); // 두 번째 모달(EDIT)
  const [openPhotoModal, setOpenPhotoModal] = useState(false);   // 세 번째 모달(사진 선택)

  // 클릭한 박스 인덱스
  const [currentBoxIndex, setCurrentBoxIndex] = useState<number | null>(null);

  // --------------------------
  // (B) 임시 이미지 상태(두 번째 모달)
  // --------------------------
  const [tempImageFile, setTempImageFile] = useState<File | null>(null);
  const [tempPreviewUrl, setTempPreviewUrl] = useState<string | null>(null);

  // 업로드 로딩
  const [isUploading, setIsUploading] = useState(false);

  // --------------------------
  // (C) 메시지 입력(두 번째 모달)
  // --------------------------
  const [tempMessage, setTempMessage] = useState<string>('');  
  const [isEditingMessage, setIsEditingMessage] = useState<boolean>(false);

  // 숨겨진 input ref
  const fileInputRef = useRef<HTMLInputElement>(null);
  const cameraInputRef = useRef<HTMLInputElement>(null);

  // --------------------------
  // 4) 첫 번째 모달 열기
  // --------------------------
  const handleOpen = (index: number) => {
    setCurrentBoxIndex(index);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  // 첫 번째 모달에서 EDIT 누르면 -> 두 번째 모달 열기
  const openSecondModalWithText = (index: number) => {
    // 모달 열기 전, 임시 이미지/메시지 초기화
    setTempImageFile(null);
    setTempPreviewUrl(null);
    setIsEditingMessage(true);

    // 해당 박스의 기존 텍스트를 tempMessage에 로드
    setTempMessage(dreamTexts[index] || '');

    setOpenModal(false);
    setOpenSecondModal(true);
  };

  const handleCloseSecondModal = () => {
    setOpenSecondModal(false);
  };

  // 세 번째 모달
  const handleClosePhotoModal = () => setOpenPhotoModal(false);

  // --------------------------
  // 5) Cloudinary 업로드 함수
  // --------------------------
  const uploadImageToCloudinary = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

    const response = await fetch(CLOUDINARY_URL, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      throw new Error('Cloudinary 업로드 실패');
    }
    const data = await response.json();
    return data.secure_url;
  };

  // --------------------------
  // 6) (세 번째 모달) 파일 선택
  // --------------------------
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const previewUrl = URL.createObjectURL(file);
    setTempImageFile(file);
    setTempPreviewUrl(previewUrl);

    // 세 번째 모달 닫기
    handleClosePhotoModal();
  };

  // --------------------------
  // 7) 두 번째 모달(SAVE)
  // --------------------------
  const handleSaveImage = async () => {
    if (currentBoxIndex === null) {
      alert('선택된 박스가 없습니다!');
      return;
    }
  
    setIsUploading(true);
    try {
      // (A) dreamTexts 복사
      const newDreamTexts = [...dreamTexts];
      // 현재 박스 인덱스 텍스트를 tempMessage로 교체
      newDreamTexts[currentBoxIndex] = tempMessage;

      // '@@@'로 합쳐서 custom:dreamtext에 저장
      const newTextString = newDreamTexts.join('@#$');

      // Cognito 업데이트 용 객체
      const attributesToUpdate: { [key: string]: string } = {
        'custom:dreamtext': newTextString,
      };

      // 이미지가 있다면 업로드
      if (tempImageFile) {
        const uploadedUrl = await uploadImageToCloudinary(tempImageFile);

        // Redux 업데이트
        dispatch(updateDreamImage({ index: currentBoxIndex, url: uploadedUrl }));

        const imageAttributeName = `custom:dream${currentBoxIndex + 1}`;
        attributesToUpdate[imageAttributeName] = uploadedUrl;
      }

      // Cognito에 반영
      await updateUserAttributes(attributesToUpdate);

      // 로컬 state 업데이트
      setDreamTexts(newDreamTexts);

      alert('저장되었습니다!');

      // 임시 상태 초기화
      setTempImageFile(null);
      setTempPreviewUrl(null);

      // 두 번째 모달 닫기
      setOpenSecondModal(false);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert('알 수 없는 오류가 발생했습니다.');
      }
    } finally {
      setIsUploading(false);
    }
  };

  // --------------------------
  // 8) 앨범에서 찾기 / 카메라 촬영
  // --------------------------
  const handleSelectFromLibrary = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleTakePhoto = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  return (
    <Layout>
      {/* ===== 메인 화면 배경 ===== */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '55px',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            position: 'relative',
            height: 'calc(100vh - 155px)',
            overflowY: 'auto',
          }}
        >
          {/* 상단 헤더(타이틀, 알림 아이콘) */}
          <Box sx={{ position: 'relative', width: '100%', height: 60 }}>
            <Typography variant="h6" sx={{ position: 'absolute', top: 16, left: 16, fontWeight: 'bold' }}>
              Visionboard
            </Typography>
            <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
              <IconButton>
                <NotificationsIcon />
              </IconButton>
            </Box>
          </Box>

          {/* ---- 흰색 큰 박스 (7개 박스 위치) ---- */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '91%',
              backgroundColor: '#FFC9C9',
              borderRadius: 2,
            }}
          >
            {/* 7개의 박스 */}
            {[...Array(7)].map((_, i) => (
              <Box
                key={i}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  boxShadow: 5,
                  ...(i === 0 && { top: '2%',  left: '2%',  width: '48%', height: '21.5%' }),
                  ...(i === 1 && { top: '25%', left: '2%',  width: '48%', height: '21.5%' }),
                  ...(i === 2 && { top: '6%',  left: '52%', width: '46%', height: '21.5%' }),
                  ...(i === 3 && { top: '29%', left: '52%', width: '46%', height: '21.5%' }),
                  ...(i === 4 && { top: '48%', left: '2%',  width: '46%', height: '24.5%' }),
                  ...(i === 5 && { top: '52%', left: '50.5%', width: '47.5%', height: '20.5%' }),
                  ...(i === 6 && { top: '74%', left: '18.5%', width: '79.5%', height: '24%' }),
                  backgroundColor: dreamImages[i] ? 'transparent' : '#ccc',
                  backgroundImage: dreamImages[i] ? `url(${dreamImages[i]})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                onClick={() => handleOpen(i)}
              />
            ))}

            {/* 텍스트들 */}
            <Typography variant="h5" sx={{ position: 'absolute', top: '1%', right: '10%', color: '#283593', fontWeight: 'bold' }}>
              My dream
            </Typography>
            <Typography
              variant="h4"
              sx={{
                position: 'absolute',
                top: '97%',
                left: '3%',
                color: '#283593',
                fontWeight: 'bold',
                transform: 'rotate(-90deg)',
                transformOrigin: 'left top',
              }}
            >
              K-culture
            </Typography>
            {/* 중앙 “2025” */}
            <Box
              sx={{
                position: 'absolute',
                top: '46.5%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                px: 2,
                py: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" sx={{ color: '#283593', fontWeight: 'bold' }}>
                2025
              </Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
                position: 'absolute',
                top: '18%',
                left: '2%',
                color: '#7B3F00',
                fontWeight: 'bold',
                transform: 'rotate(-15deg)',
                backgroundColor: 'white',
                px: 1,
              }}
            >
              SELFCARE
            </Typography>
            <Typography
              variant="h6"
              sx={{
                position: 'absolute',
                top: '70%',
                right: '1%',
                color: '#228B22',
                fontWeight: 'bold',
                transform: 'rotate(10deg)',
                backgroundColor: 'white',
                px: 1,
              }}
            >
              VISION
            </Typography>
          </Box>
        </Container>
      </Box>

      {/*
        -------------------------------------
        (1) 첫 번째 모달
        -------------------------------------
      */}
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '88%',
            maxWidth: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            pl: 3,
            pr: 3,
            pt:'60px',
            pb: '60px',
            borderRadius: 2,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* 이미지 미리보기 */}
          <Box
            sx={{
              width: '90%',
              height: 200,
              backgroundColor: '#ccc',
              borderRadius: 3,
              mb: 3,
              overflow: 'hidden',
            }}
          >
            {currentBoxIndex !== null && dreamImages[currentBoxIndex] && (
              <img
                src={dreamImages[currentBoxIndex]}
                alt="BoxImage"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </Box>

          {/* 텍스트 표시 (12자 단위로 줄바꿈) */}
          {currentBoxIndex !== null && (
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', mb: 3, color: '#555', whiteSpace: 'pre-line' }}
            >
              {dreamTexts[currentBoxIndex].trim().length > 0
                ? wrapText(dreamTexts[currentBoxIndex], 12)
                : `올해를 살아갈 나를 위해\n응원메시지를 적어주세요.`}
            </Typography>
          )}

          {/* 버튼 영역 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              variant="outlined"
              sx={{ width: '48%' }}
              onClick={() => {
                if (currentBoxIndex !== null) {
                  openSecondModalWithText(currentBoxIndex);
                }
              }}
            >
              EDIT
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ width: '48%' }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Modal>

      {/*
        -------------------------------------
        (2) 두 번째 모달 (EDIT)
        -------------------------------------
      */}
      <Modal open={openSecondModal} onClose={handleCloseSecondModal}>
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '88%',
            maxWidth: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            pl: 3,
            pr: 3,
            pt:'60px',
            pb: '60px',
            borderRadius: 2,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* 임시 이미지 미리보기 */}
          <Box
            sx={{
              width: '90%',
              height: 200,
              backgroundColor: '#ccc',
              borderRadius: 3,
              mb: 2,
              overflow: 'hidden',
            }}
          >
            {tempPreviewUrl ? (
              <img
                src={tempPreviewUrl}
                alt="TempPreview"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              currentBoxIndex !== null &&
              dreamImages[currentBoxIndex] && (
                <img
                  src={dreamImages[currentBoxIndex]}
                  alt="BoxImage"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              )
            )}
          </Box>

          {/* 사진변경 -> 세 번째 모달(사진 선택) */}
          <Button variant="outlined" sx={{ mb: 3 }} onClick={() => setOpenPhotoModal(true)}>
            사진변경
          </Button>

          {/* 메시지 입력 영역 */}
          {isEditingMessage ? (
            <TextField
            value={tempMessage}
            onChange={(e) => setTempMessage(e.target.value)}
            multiline
            rows={3}
            fullWidth
            placeholder={`올해를 살아갈 나를 위해
  응원메시지를 적어주세요.`}
            inputProps={{ style: { textAlign: 'center', whiteSpace: 'pre-wrap' } }}
            sx={{ mb: 3 }}
          />
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', mb: 3, color: '#555', cursor: 'pointer' }}
              onClick={() => setIsEditingMessage(true)}
            >
              {tempMessage.trim().length > 0
                ? tempMessage
                : `올해를 살아갈 나를 위해\n응원메시지를 적어주세요.`}
            </Typography>
          )}

          {/* SAVE / CANCEL 버튼 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              variant="contained"
              sx={{ width: '48%' }}
              onClick={handleSaveImage}
              disabled={isUploading}
            >
              {isUploading ? <CircularProgress size={24} /> : 'SAVE'}
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ width: '48%' }}
              onClick={handleCloseSecondModal}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Modal>

      {/*
        -------------------------------------
        (3) 세 번째 모달 (사진 선택)
        -------------------------------------
      */}
      <Modal open={openPhotoModal} onClose={handleClosePhotoModal}>
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 350,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button variant="outlined" fullWidth onClick={handleSelectFromLibrary}>
            앨범에서 찾기
          </Button>
          <Button variant="outlined" fullWidth onClick={handleTakePhoto}>
            카메라 촬영
          </Button>

          <Button variant="outlined" color="error" onClick={handleClosePhotoModal} fullWidth>
            CANCEL
          </Button>
        </Box>
      </Modal>

      {/* 숨겨진 input들 (앨범/카메라) */}
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <input
        type="file"
        accept="image/*"
        capture="user"
        style={{ display: 'none' }}
        ref={cameraInputRef}
        onChange={handleFileChange}
      />
    </Layout>
  );
};

export default Dream;
