import { useEffect } from 'react';
import { App } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';

export function useDeepLinking() {
  const navigate = useNavigate();

  useEffect(() => {
    let subscription: any;

    const setupListener = async () => {
      subscription = await App.addListener('appUrlOpen', (data: { url: string }) => {
        console.log('앱이 URL로 열림:', data.url);
        if (data.url.includes('/google-callback')) {
            alert('구글 콜백 URL로 이동합니다.');
          navigate('/google-callback');
        } else if (data.url.includes('/line-callback')) {
          navigate('/line-callback');
        } else if (data.url.includes('/kakao-callback')) {
          navigate('/kakao-callback');
        } else if (data.url.includes('/apple-callback')) {
          navigate('/apple-callback');
        }
      });
    };

    setupListener();

    return () => {
      if (subscription) {
        subscription.remove();
      }
    };
  }, [navigate]);
}
