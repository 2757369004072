import React, { useState } from 'react';
import { forgotPassword, confirmNewPassword } from '../../AuthService/AuthService';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');           // 사용자 이메일
  const [code, setCode] = useState('');             // 인증 코드
  const [newPassword, setNewPassword] = useState(''); // 새 비밀번호
  const [step, setStep] = useState(1);              // 현재 단계 (1: 이메일입력 → 2: 인증코드 & 비밀번호입력)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  /**
   * 1) 비밀번호 재설정 이메일 전송(Forgot Password)
   */
  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await forgotPassword(email);
      alert('입력하신 이메일로 인증 코드가 전송되었습니다.');
      setStep(2); // 2단계로 이동
    } catch (err: any) {
      console.error('비밀번호 재설정 코드 요청 실패:', err);
      setError('인증번호가 틀렸습니다.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * 2) 인증 코드 + 새 비밀번호로 비밀번호 최종 변경
   */
  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await confirmNewPassword(email, code, newPassword);
      alert('비밀번호가 성공적으로 재설정되었습니다. 다시 로그인 해주세요.');
      navigate('/signin'); // 비밀번호 재설정 완료 후 로그인 페이지로 이동
    } catch (err: any) {
      console.error('비밀번호 재설정 실패:', err);
      setError('비밀번호 재설정에 실패하였습니다. 비밀번호 규칙을 확인해주세요.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {step === 1 && (
        <form onSubmit={handleSendCode} style={styles.form}>
          <h2 style={styles.title}>비밀번호 찾기</h2>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.inputGroup}>
            <label>이메일을 입력해주세요.</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
              placeholder="example@email.com"
            />
          </div>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? '코드 전송 중...' : '인증 코드 보내기'}
          </button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleResetPassword} style={styles.form}>
          <h2 style={styles.title}>비밀번호 재설정</h2>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.inputGroup}>
            <label>인증 코드</label>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              style={styles.input}
              placeholder="이메일로 받은 인증 코드를 입력하세요."
            />
          </div>
          <div style={styles.inputGroup}>
            <label>새 비밀번호</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              style={styles.input}
              placeholder="새로운 비밀번호"
            />
          </div>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? '재설정 중...' : '비밀번호 재설정'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  inputGroup: {
    marginBottom: '1rem',
  },
  input: {
    width: '100%',
    padding: '0.5rem',
    marginTop: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  title: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#d3a264',
    color: '#fff',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginBottom: '1rem',
    textAlign: 'center',
  },
};
