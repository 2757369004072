import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { getCurrentUser } from '../../AuthService/AuthService';

interface AuthContextType {
  user: CognitoUser | null;
  session: CognitoUserSession | null;
  setUser: (user: CognitoUser | null) => void;
  setSession: (session: CognitoUserSession | null) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  session: null,
  setUser: () => {},
  setSession: () => {},
  logout: () => {},
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // 로딩 상태 추가

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          console.error('세션 가져오기 에러:', err);
          setUser(null);
          setSession(null);
        } else {
          setUser(currentUser);
          setSession(session);
        }
        setLoading(false); // 세션 확인 후 로딩 상태 해제
      });
    } else {
      setLoading(false); // 사용자가 없으면 로딩 상태 해제
    }
  }, []);

  const handleLogout = () => {
    if (user) {
      user.signOut(); // Cognito에서 세션 제거
      setUser(null);
      setSession(null);
    }
  };

  if (loading) {
    return <div>로딩 중...</div>; // 로딩 스피너 또는 메시지 표시
  }

  return (
    <AuthContext.Provider value={{ user, session, setUser, setSession, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
