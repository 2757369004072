import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase,
  Collapse,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';

const Faq = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null); // Track which question is expanded

  const handleBack = () => {
    navigate(-1);
  };

  // FAQ items with their corresponding answers
  const faqData = [
    {
      question: '내 계정 정보를 어떻게 변경할 수 있나요?',
      answer: '어플 내 [설정] → [계정 관리]에서 이메일, 비밀번호, 프로필 사진 등의 계정 정보를 변경할 수 있습니다.\n필요 시 고객센터에 문의하시면 추가 도움을 받을 수 있습니다.'
    },
    {
      question: '구독을 취소하려면 어떻게 해야 하나요?',
      answer: '구독은 사용 중인 앱 스토어(예: Google Play Store 또는 Apple App Store)에서 취소 가능합니다\n1) 앱 스토어로 이동\n2) [구독] 또는 [결제 관리] 메뉴 선택\n3) 해당 구독 취소 진행\n취소를 완료하면 다음 결제일부터 요금이 청구되지 않습니다.'
    },
    {
      question: '어플이 정상적으로 작동하지 않을 때 어떻게 해결할 수 있나요?',
      answer: '다음 방법으로 문제를 해결해 보세요.\n1) 어플을 완전히 종료 후 다시 실행\n2) 최신 버전으로 업데이트\n3) 기기 재부팅\n4) 네트워크 상태 확인\n그래도 문제가 해결되지 않을 경우 고객센터로 문의해 주시면 신속히 도와드리겠습니다.'
    },
    {
      question: '데이터가 다른 기기에서도 동기화되나요?',
      answer: '프리미엄 플랜 사용자는 데이터가 실시간으로 모든 기기에서 동기화됩니다. 무료 플랜 사용자는 기본 데이터만 동기화되며, 일부 고급 데이터는 동기화가 제한됩니다.'
    },
    {
      question: '기록된 데이터는 어떻게 백업할 수 있나요?',
      answer: '기록된 데이터는 [설정] → [데이터 관리] → [백업] 메뉴에서 백업할 수 있습니다. 백업 파일은 다음과 같이 저장됩니다:\n1) 클라우드 서비스(Google Drive, iCloud 등)\n2) 로컬 저장소(기기에 다운로드)\n원활한 데이터 보관을 위해 정기적인 백업을 권장합니다.'
    },
  ];

  // Handle question click
  const handleQuestionClick = (index: number): void => {
    setExpandedIndex(index === expandedIndex ? null : index); // Toggle expansion
  };

  // Filter FAQ items based on search term
  const filteredFaqData = faqData.filter((item) =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '55px',
        alignItems: 'center',
      }}
    >
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            자주 묻는 질문
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          width: '90%',
          marginTop: '20px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            padding: '5px 10px',
            backgroundColor: '#f0f0f0',
            borderRadius: '5px',
          }}
        >
          <SearchIcon />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="검색어를 입력하세요"
            inputProps={{ 'aria-label': '검색어를 입력하세요' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <List>
          {filteredFaqData.map((item, index) => (
            <Box key={index}>
              <ListItem 
                sx={{ 
                  borderBottom: expandedIndex === index ? 'none' : '1px solid #f0f0f0',
                  cursor: 'pointer' 
                }}
                onClick={() => handleQuestionClick(index)}
              >
                <ListItemIcon>
                  <HelpOutlineIcon sx={{ color: 'red' }} />
                </ListItemIcon>
                <ListItemText primary={item.question} />
              </ListItem>
              <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    backgroundColor: '#FFF8E1', // Light beige background for the answer
                    padding: '15px',
                    marginLeft: '40px',
                    marginRight: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    borderBottom: '1px solid #f0f0f0'
                  }}
                >
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>{item.answer}</Typography>
                </Box>
              </Collapse>
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Faq;