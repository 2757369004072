import { createSlice, createSelector } from '@reduxjs/toolkit';

// 메시지 한 건의 타입(예시)
interface Message {
  text: string;
  textEng?: string;
  highlight: string;
  highlightEng?: string;
  background: string;
  textColor: string;
  audioUrl: string;
}

// [테마][카테고리][난이도][day] -> Message[] 형태
interface MessagesState {
  [theme: string]: {
    [category: string]: {
      [difficulty: string]: {
        [day: string]: Message[];
      };
    };
  };
}

// 초기 상태: 5개의 테마와 카테고리/난이도/day 구조
const initialState: MessagesState = {
  // 1. 일상 테마
  "일상": {
    "친구": {
      "초급": {
        Day1: [
          {
            text: "아 오늘 날씨 겁나 덥다..",
            textEng: "Gosh..Freaking hot today..",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY1_1.mp3"
          },
          {
            text: "그니까..! 아아 땡겨!",
            textEng: "Totally..! I'm craving an iced Americano!",
            highlight: "아아",
            highlightEng: "an iced Americano!",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY1_2.mp3"
          }
        ],
        Day2: [
          {
            text: "아.. 나 핸드폰 배터리 1%야 ㅠㅠ",
            textEng: "Ugh, my phone's at 1% 😭",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY2_1.mp3"
          },
          {
            text: "너 혹시 충전기 있어?",
            textEng: "Do you have a charger on you?",
            highlight: "충전기",
            highlightEng: "a charger",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY2_2.mp3"
          }
        ],
        Day3: [
          {
            text: "이번 주말에 약속있어?",
            textEng: "Got any plans this weekend?",
            highlight: "약속",
            highlightEng:"plans",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY3_1.mp3"
          },
          {
            text: "나 이번에 나온 영화 보려구!",
            textEng: "I'm gonna go watch that new movie!",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY3_2.mp3"
          }
        ],
        Day4: [
          {
            text: "원영이 생일 파티 뭐 해줄까?",
            textEng: "What should we do for [Other's name]'s B-day?",
            highlight: "생일",
            highlightEng:"B-day",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY4_1.mp3"
          },
          {
            text: "서프라이즈로 예쁜 케익 준비하자!",
            textEng: "Let's surprise her with a cute cake!",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY4_2.mp3"
          },
          {
            text: "그럼 내가 케익 주문 해둘께 ㅎㅎ",
            textEng: "Cool, I'll order the cake then 😌",
            highlight: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY4_3.mp3"
          }
        ],
        Day5: [
          {
            text: "야, 너 그 옷 되게 예쁘다! 어디서 샀어?",
            textEng: "Hey, that outfit is fire! Where'd you get it?",
            highlight: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY5_1.mp3"
          },
          {
            text: "이거 강남에 편집샵! 다음에 같이 보러가자!",
            textEng: "Oh, this? It's from a shop in Gangnam. Let's go check it out together sometime!",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY5_2.mp3"
          },
          {
            text: "좋아! 이번 달은 쇼핑 좀 해야겠어!",
            textEng: "Bet! I need to do some shopping this month anyway!",
            highlight: "이번 달",
            highlightEng:"this month",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY5_3.mp3"
          }
        ],
        Day6: [
          {
            text: "너 어디야? 나 성수역 1번 출구 앞에있어.",
            textEng: "Where you at? I'm by Exit 1 at Seongsu station.",
            highlight: "출구",
            highlightEng: "Exit",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY6_1.mp3"
          },
          {
            text: "나도 거의 다 왔어! 5분만 기다려줘.",
            textEng: "Almost there! Just give me like 5 minutes.",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY6_2.mp3"
          },
          {
            text: "근데 오늘 날씨 미친 것 같아. 너무 추워,,",
            textEng: "Girl, the weather's insane today. It's freezing.",
            highlight: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY6_3.mp3"
          },
          {
            text: "내 말이,, 패딩 꺼내 놔야겠어.",
            textEng: "For real. I need to bust out my puffer coat.",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY6_4.mp3"
          }
        ],
        Day7: [
          {
            text: "이 사진 인스타에 올려도 돼?",
            textEng: "Can I post this on Insta?",
            highlight: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY7_1.mp3"
          },
          {
            text: "잠깐만. 나 포샵 좀 하게 ㅎㅎ",
            textEng: "Wait, lemme edit it real quick 😂",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY7_2.mp3"
          },
          {
            text: "최대한 자연스럽게 해. 생얼인 것 처럼 ㅋㅋ",
            textEng: "Make it look super natural, like no filter vibes lol",
            highlight: "자엽스럽게",
            highlightEng:"natural",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY7_3.mp3"
          },
          {
            text: "어렵게지만 해볼게 ㅋㅋ",
            textEng: "It might be hard, but😂",
            highlight: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC+-%E1%84%8E%E1%85%B5%E1%86%AB%E1%84%80%E1%85%AE-/DAY7_4.mp3"
          }
        ]
      },
      "중급": {
        "Day1": [
          {
            text: '오랜만이네. 그동안 무슨 일 있었어?',
            textEng: "It's been a while. What's been happening with you?",
            highlight: '그동안',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio4.mp3',
          },
          {
            text: '별일 없었어. 근데 지난주에 승진했어!',
            textEng: 'Nothing special. But I got promoted last week!',
            highlight: '승진했어',
            background: '#FFE4E1',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
        "Day2": [
          {
            text: '친구 테마 중급 Day2 예시 문장입니다.',
            textEng: 'This is a sample sentence for Friends theme Intermediate Day2.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '요즘 어떻게 지내? 들리는 바로는 해외 주재원으로 발령받았다며?',
            textEng: 'How have you been lately? I heard you got assigned as an overseas expatriate?',
            highlight: '해외 주재원',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
    },
    "썸": {
      "초급": {
        Day1: [
          {
            text: "너랑 얘기하다 보면 시간 진짜 빨리 가는 것 같아 ㅎㅎ",
            textEng: "Talking to you makes time fly so fast lol",
            highlight: "빨리",
            highlightEng: "fast",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY1_1.mp3"
          },
          {
            text: "정말? 나도 네가 말 잘 걸어줘서 편한 것 같아.",
            textEng: "Really? I feel so comfortable since you're so easy to talk to.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY1_2.mp3"
          }
        ],
        Day2: [
          {
            text: "나 오늘 삼겹살 땡기는데, 너는 무슨 음식 좋아해?",
            textEng: "I'm craving samgyeopsal today. What's your favorite food?",
            highlight: "음식",
            highlightEng: "food",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY2_1.mp3"
          },
          {
            text: "삼겹살 나도 환장하지! 언제 같이 먹으러 가자 ㅎㅎ",
            textEng: "I'm obsessed with samgyeopsal too! Let's go eat it together sometime haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY2_2.mp3"
          }
        ],
        Day3: [
          {
            text: "최근에 넷플 뭐 재밌는거 봤어?",
            textEng: "Seen anything good on Netflix lately?",
            highlight: "최근",
            highlightEng: "lately",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY3_1.mp3"
          },
          {
            text: "나 원래는 로맨스 좋아하는데 요즘 오징어게임에 빠졌어 ㅋㅋ",
            textEng: "I usually like romance, but lately, I've been hooked on Squid Game 😂",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY3_2.mp3"
          }
        ],
        Day4: [
          {
            text: "너 주말에 보통 뭐 해?",
            textEng: "What do you usually do on weekends?",
            highlight: "주말",
            highlightEng: "weekends",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY4_1.mp3"
          },
          {
            text: "음.. 나 그냥 집에서 쉬거나 운동하는 것 같아. 너는?",
            textEng: "Hmm… I mostly just chill at home or work out. What about you?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY4_2.mp3"
          },
          {
            text: "나도 비슷해 ㅎㅎ그럼 다음엔 한강 같이 러닝할래?",
            textEng: "Pretty much the same lol Wanna go running at Han River next time?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY4_3.mp3"
          }
        ],
        Day5: [
          {
            text: "너 오늘 표정이 왜 그래? 요즘 스트레스 받는 일 있어?",
            textEng: "Why do you look like that today? Are you stressed about something?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY5_1.mp3"
          },
          {
            text: "아.. 요즘 회사 일이 바빠서 정신이 없네ㅜㅜ 그래도 오늘은 너 만나니까 힐링 된다.",
            textEng: "Ugh, work's been so hectic lately 😩 But honestly, seeing you today is such a mood booster.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY5_2.mp3"
          },
          {
            text: "그렇구나..! 그럼 오늘은 완전 스트레스 풀어줘야겠다! ㅎㅎ",
            textEng: "Aw, I see! Then today's all about helping you de-stress haha",
            highlight: "그렇구나",
            highlightEng: "I see",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY5_3.mp3"
          }
        ],
        Day6: [
          {
            text: "나 요즘 한국 발라드 듣는 거에 빠졌어. 너는 무슨 장르 좋아해?",
            textEng: "I've been super into Korean ballads lately. What kind of music do you like?",
            highlight: "빠졌어",
            highlightEng: "into",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY6_1.mp3"
          },
          {
            text: "나 평소에 팝송이나 R&B들어! 한국 노래 뭐 하나 추천해줘 ㅎㅎ",
            textEng: "I usually listen to pop or R&B! Recommend me a Korean song!",
            highlight: "한국 노래 뭐 하나 추천해줘",
            highlightEng: "Recommend me a Korean song",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY6_2.mp3"
          },
          {
            text: "나 성시경의 \"너의 모든 순간\" 좋아해. 나의 최애 드라마 OST 거든 ㅎㅎ",
            textEng: "I love Sung Si-kyung's Every Moment of You. It's my favorite drama's soundtrack haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY6_3.mp3"
          },
          {
            text: "오~ 한번 들어봐야겠다. 그 드라마 다음에 같이 보자!",
            textEng: "Ooh~ I'll check it out. Let's watch that drama together sometime!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY6_4.mp3"
          }
        ],
        Day7: [
          {
            text: "아~ 뭔가 어디 떠나고 싶다 ㅎㅎ",
            textEng: "Ah~ I feel like I need to go somewhere lol",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY7_1.mp3"
          },
          {
            text: "해외? 한국에도 서울 말고 여행 갈 곳 많아 ㅎㅎ",
            textEng: "Abroad? There are so many places to visit in Korea besides Seoul, you know!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY7_2.mp3"
          },
          {
            text: "오 어디가 좋아? 난 서울이랑 부산 가봐서 다른 곳도 가보고 싶어 ㅎㅎ",
            textEng: "Oh, where's good? I've only been to Seoul and Busan, so I wanna try somewhere new.",
            highlight: "어디",
            highlightEng: "where",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY7_3.mp3"
          },
          {
            text: "그럼 제주도 어때? 사실 나도 안가봤는데 궁금했거든 ㅎㅎ",
            textEng: "How about Jeju Island? I've never been either, but I've always been curious haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC-%E1%84%8A%E1%85%A5%E1%86%B7-/DAY7_4.mp3"
          }
        ]
      },
      "중급": {
        "Day1": [
          {
            text: '썸 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Flirting theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '썸 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Flirting theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
  },
  
  // 2. 비즈니스 테마
  "비즈니스": {
    "출근": {
      "초급": {
        "Day1": [
          {
            text: "안녕하세요. 오늘이 첫 출근입니다. 잘 부탁드립니다.",
            textEng: "Hello, today's my first day at work. Please take care of me.",
            highlight: "오늘",
            highlightEng: "today",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY1_1.mp3"
          },
          {
            text: "반갑습니다.\n저도 잘 부탁드립니다.",
            textEng: "Nice to meet you. I'm looking forward to working with you too.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "몇 시까지 출근해야하나요?",
            textEng: "What time should I come in for work?",
            highlight: "몇 시",
            highlightEng: "What time",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY2_1.mp3"
          },
          {
            text: "9시까지 출근하시면 됩니다.",
            textEng: "Please arrive by 9 a.m.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "오늘 출근할 때 필요한 게 있나요?",
            textEng: "Is there anything I need to bring on my first day?",
            highlight: "필요한?",
            highlightEng: "need",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY3_1.mp3"
          },
          {
            text: "노트북만 준비해주세요.",
            textEng: "Just your laptop will be fine.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "제가 첫 출근인데 무엇부터 일하면 될까요?",
            textEng: "Since it's my first day, what should I start with?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY4_1.mp3"
          },
          {
            text: "첫 출근이시니 업무보다는 메뉴얼 숙지 부탁드립니다.",
            textEng: "Please focus on familiarizing yourself with the manual, since it's your first day.",
            highlight: "첫",
            highlightEng: "first",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY4_2.mp3"
          },
          {
            text: "네 감사합니다. 팀장님",
            textEng: "Got it, thank you, [Other's name].",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "죄송합니다. 지하철이 막혀서 미팅에 조금 늦을 것 같습니다.",
            textEng: "I'm sorry, the subway is delayed, so I might be a bit late for the meeting.",
            highlight: "지하철",
            highlightEng: "the subway",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY5_1.mp3"
          },
          {
            text: "네. 조심히 오세요.\n다음부터는 주의 부탁드립니다.",
            textEng: "Alright, take your time. Please be more mindful next time.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY5_2.mp3"
          },
          {
            text: "네 죄송합니다.",
            textEng: "Yes, I'm sorry.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "회사 주소가 어디인가요?",
            textEng: "What's the company address?",
            highlight: "회사 주소",
            highlightEng: "the company address",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY6_1.mp3"
          },
          {
            text: "서울시 강남구 테헤란로 123번지입니다.",
            textEng: "It's 123 Teheran-ro, Gangnam-gu, Seoul.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY6_2.mp3"
          },
          {
            text: "강남역에서 가까운가요?",
            textEng: "Is it close to Gangnam Station?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY6_3.mp3"
          },
          {
            text: "네, 5번 출구에서 도보로 5분 거리입니다.",
            textEng: "Yes, it's about a 5-minute walk from Exit 5.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "출근할 때 저도 자차를 끌고 다니려고 합니다. 부장님도 끌고 다니시나요?",
            textEng: "I'm planning to drive to work. Do you drive as well?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY7_1.mp3"
          },
          {
            text: "네, 저도 자차로 출퇴근 합니다.",
            textEng: "Yes, I commute by car too.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY7_2.mp3"
          },
          {
            text: "회사 주차장은 넓은편인가요?",
            textEng: "Is the company parking lot spacious?",
            highlight: "주차장",
            highlightEng: "parking",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY7_3.mp3"
          },
          {
            text: "네, 지하주차장은 넓습니다. 주차하시는데 불편하지않을 거에요.",
            textEng: "Yes, the underground parking lot is large, so parking won't be an issue.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%B3%E1%86%AB-/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '출근 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Commuting theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio4.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '출근 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Commuting theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
    },
    "미팅": {
      "초급":{
        "Day1": [
          {
            text: "미팅은 몇 시에 시작하나요?",
            textEng: "What time does the meeting start?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY1_1.mp3"
          },
          {
            text: "오전 10시에 시작합니다.",
            textEng: "It starts at 10 a.m.",
            highlight: "오전",
            highlightEng: "a.m.",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "다음 주에 미팅 가능하세요?",
            textEng: "Are you available for a meeting next week?",
            highlight: "다음 주",
            highlightEng: "next week",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY2_1.mp3"
          },
          {
            text: "네, 가능합니다. 언제가 괜찮으세요?",
            textEng: "Yes, I'm available. When works for you?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "오늘 미팅 감사합니다.",
            textEng: "Thank you for today's meeting.",
            highlight: "today",
            highlightEng: "today",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY3_1.mp3"
          },
          {
            text: "저도 감사합니다. 다시 연락드리겠습니다.",
            textEng: "Thank you as well. I'll follow up with you soon.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "팀장님, 이번 미팅에서는 무엇을 논의하나요?",
            textEng: "[Other's name], what's the agenda for this meeting?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY4_1.mp3"
          },
          {
            text: "프로젝트 진행상황을 공유할 예정입니다.",
            textEng: "We'll be sharing the project's progress.",
            highlight: "진행상황",
            highlightEng: "progress",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY4_2.mp3"
          },
          {
            text: "네. 맞춰서 준비해가겠습니다.",
            textEng: "Understood. I'll prepare accordingly.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "미팅에 몇 명이 참석하나요?",
            textEng: "How many people will be attending the meeting?",
            highlight: "참석",
            highlightEng: "attend",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY5_1.mp3"
          },
          {
            text: "총 8명이 참석할 예정입니다.",
            textEng: "A total of eight people are expected.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY5_2.mp3"
          },
          {
            text: "네. 인원수에 맞춰 프린트 자료 준비해가겠습니다.",
            textEng: "Alright, I'll prepare printed materials for the group.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "미팅에 필요한 자료를 이메일로 받을 수 있을까요?",
            textEng: "Can I get the necessary documents via email?",
            highlight: "자료",
            highlightEng: "documents",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY6_1.mp3"
          },
          {
            text: "네. 조금 이따 보내드리겠습니다.",
            textEng: "Sure, I'll send them to you shortly.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY6_2.mp3"
          },
          {
            text: "감사합니다.\n미팅 전에 검토해보겠습니다.",
            textEng: "Thank you. I'll review them before the meeting.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY6_3.mp3"
          },
          {
            text: "네, 준비하시기 편하게 최대한 빨리 보내드리겠습니다.",
            textEng: "I'll send them as soon as possible to help with your preparation.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "미팅 자료는 인쇄해야 하나요?",
            textEng: "Do I need to print the materials for the meeting?",
            highlight: "인쇄",
            highlightEng: "print",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY7_1.mp3"
          },
          {
            text: "아니요. 태블릿으로 볼 수 있으니 인쇄하지 않아도 됩니다.",
            textEng: "No, you can view them on your tablet, so printing isn't necessary.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY7_2.mp3"
          },
          {
            text: "알겠습니다. 내용 미리 검토해보겠습니다.",
            textEng: "Got it. I'll review the content beforehand.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY7_3.mp3"
          },
          {
            text: "네, 문제 없으면 말씀해주세요.",
            textEng: "Let me know if there are any issues.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%82%E1%85%B5%E1%84%89%E1%85%B3-%E1%84%86%E1%85%B5%E1%84%90%E1%85%B5%E1%86%BC-/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '미팅 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Meeting theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '미팅 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Meeting theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
  },
  
  // 3. 여행 테마
  "여행": {
    "쇼핑": {
      "초급":{
        "Day1": [
          {
            text: "우와 이거 너무 예쁘다 ㅎㅎ 하나 살까?",
            textEng: "Wow, this is so pretty! Should I get one?",
            highlight: "예쁘다",
            highlightEng: "pretty",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY1_1.mp3"
          },
          {
            text: "오 좋은데? 너 가방이랑 잘 어울리는 것 같아 ㅎㅎ",
            textEng: "Ooo, good choice! It goes well with your bag.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "가족이나 친구들 선물로 뭐 사가져갈까?",
            textEng: "What should I get for my family or friends?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY2_1.mp3"
          },
          {
            text: "여기 특산품이나 기념품 같은 건 어때? ㅎㅎ",
            textEng: "How about some local specialties or souvenirs?",
            highlight: "기념품",
            highlightEng: "souvenirs",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "혹시 이 머그컵 다른 색상은 없을까요?",
            textEng: "Do you have this mug in other colors?",
            highlight: "색상",
            highlightEng: "colors",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY3_1.mp3"
          },
          {
            text: "죄송하지만 이건 노랑색으로만 나와있어요.",
            textEng: "Sorry, it only comes in yellow.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "와.. 역시 부산에서 보는 생선은 크기부터 다르네.",
            textEng: "Whoa… The fish in Busan are huge!",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY4_1.mp3"
          },
          {
            text: "그러게.. 굴 시식 할 수 있대! 먹어볼래?",
            textEng: "Right? They have fresh oysters for tasting! Wanna try?",
            highlight: "시식",
            highlightEng: "tasting",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY4_2.mp3"
          },
          {
            text: "아 진짜! 그냥 그러지말고 여기서 밥 먹고가자 ㅎㅎ",
            textEng: "For real?! You know what? Let's just eat here. haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "이 옷, 이번 시즌 제일 잘 나간 거라 하나밖에 안 남았어요!",
            textEng: "This outfit is the top seller this season! There's only one left.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY5_1.mp3"
          },
          {
            text: "아.. 너무 예쁜데 제가 예산이 조금 넘어서요..ㅜㅜ",
            textEng: "Ah… It's so pretty, but it's a bit over my budget 😭",
            highlight: "예산",
            highlightEng: "budget",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY5_2.mp3"
          },
          {
            text: "그럼 제가 10프로 할인 해드릴께요! 사가세요 ㅎㅎ",
            textEng: "Then I'll give you a 10% discount! Take it! haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "와 여기 매장은 음식도, 옷도, 화장품도 다 있네 ㅎㅎ",
            textEng: "Whoa, this place has everything—food, clothes, cosmetics haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY6_1.mp3"
          },
          {
            text: "그러게.. 우리 위층부터 내려가면서 구경할까?",
            textEng: "Right? Wanna start from the top floor and work our way down?",
            highlight: "위층",
            highlightEng: "the top floor",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY6_2.mp3"
          },
          {
            text: "좋아! 난 오늘 꼭 선글라스 사야겠어 ㅎㅎ",
            textEng: "Sounds good! I have to get sunglasses today haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY6_3.mp3"
          },
          {
            text: "그래 내가 제일 잘 어울리는걸로 골라줄께 ㅎㅎ",
            textEng: "Cool, I'll help you pick the best ones for you haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "제가 이 목걸이를 잃어버려서.. 혹시 같은 제품이 있을까요?",
            textEng: "I lost this necklace... Do you have the same one?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY7_1.mp3"
          },
          {
            text: "아.. 이 제품이 인기라 다 나가버렸어요ㅜㅜ",
            textEng: "Ah… This one's really popular, so we're all out 😭",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY7_2.mp3"
          },
          {
            text: "아 아쉽네요 ㅜㅜ 그럼 비슷한 제품 추천해주실 수 있으세요?",
            textEng: "Aww, that sucks 😭 Can you recommend something similar?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY7_3.mp3"
          },
          {
            text: "이번에 나온 신상이 정말 예뻐요. 한번 보실래요?",
            textEng: "We just got some new arrivals! Wanna check them out?",
            highlight: "신상",
            highlightEng: "new arrivals",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%89%E1%85%AD%E1%84%91%E1%85%B5%E1%86%BC/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '여행 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Travel theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '여행 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Travel theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
    "관광": {
      "초급": {
        "Day1": [
          {
            text: "와, 여기 풍경 진짜 대박이다! 나 사진 찍어줘!",
            textEng: "Whoa, the view here is insane! Take a pic for me!",
            highlight: "풍경",
            highlightEng: "the view",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY1_1.mp3"
          },
          {
            text: "그래! 와 그냥 막 찍어도 인생샷 나올것 같은데? ㅎㅎ",
            textEng: "Of course! I swear, no matter how we shoot, it'll be a masterpiece haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "오! 여기가 인스타에서 많이 추천 떴던 곳이다!",
            textEng: "Oh! This is that spot that went viral on Insta!",
            highlight: "추천",
            highlightEng: "viral",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY2_1.mp3"
          },
          {
            text: "아 정말? 어쩐지 사람들 줄 많이 썼다.",
            textEng: "For real? No wonder there's such a long line.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "이 성이 몇 백 년 됐대. 진짜 대단하지 않아?",
            textEng: "This castle is like hundreds of years old. Isn't that crazy?",
            highlight: "성",
            highlightEng: "castle",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY3_1.mp3"
          },
          {
            text: "그러게.. 옛날 사람들은 어떻게 이런 걸 만들었을까?",
            textEng: "Right? How did people even build something like this back then?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "여기 지도 보니까 다음 블록에 우측 아니야?",
            textEng: "According to the map, we should turn right at the next block.",
            highlight: "지도",
            highlightEng: "the map",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY4_1.mp3"
          },
          {
            text: "근데 우리가 잘 못 본 것 같아.. 그냥 지하철로 가자!",
            textEng: "Uh… I think we misread it. Let's just take the subway.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY4_2.mp3"
          },
          {
            text: "아 정말ㅜ 오! 저기 바로 앞에 역 있다!",
            textEng: "Ugh, really? 😭 Oh! There's a station right there!",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "저 분한테 사진 찍어달라고 부탁할까?",
            textEng: "Should we ask that person to take our picture?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY5_1.mp3"
          },
          {
            text: "그러자.. 아니면 저 분 현지인이신것 같은데 같이 찍자고 할까?",
            textEng: "Yeah! Or… they look like a local—should we ask them to take one with us?",
            highlight: "현지인",
            highlightEng: "a local",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY5_2.mp3"
          },
          {
            text: "오 좋은 아이디어네. 현지분이랑 찍으면 여행 느낌 나고 좋지 ㅎㅎ",
            textEng: "Ooo, good idea! It'll feel more like a real travel moment haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "여기가 그렇게 맛있다는 현지 맛집이야?",
            textEng: "This is the famous local restaurant, right?",
            highlight: "현지 맛집",
            highlightEng: "the famous local restaurant",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY6_1.mp3"
          },
          {
            text: "응. 근데 이런 조용한 골목길 안에 있어서 좋다 ㅎㅎ",
            textEng: "Yup! And I love how it's tucked away in a quiet alley haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY6_2.mp3"
          },
          {
            text: "그러니까. 진짜 숨은 보석을 찾은 기분이야 ㅎㅎ",
            textEng: "Same! Feels like we just found a hidden gem 😉",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY6_3.mp3"
          },
          {
            text: "그치? 사람들이 아직 많이 몰라서 더 매력적인 것 같아ㅎㅎ",
            textEng: "Right? Since not many people know about it yet, it's even better!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "여기가 그 사극에 자주 나오는 경복궁이야?",
            textEng: "Is this the palace that's always in historical dramas?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY7_1.mp3"
          },
          {
            text: "응. 엄청 큰데 하루 안에 다 볼 수 있을까? ㅎㅎ",
            textEng: "Yep! It's huge though—think we can see everything in one day? haha",
            highlight: "하루",
            highlightEng: "one day",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY7_2.mp3"
          },
          {
            text: "그러게.. 와 저기 한복 입은 사람들 봐봐! 엄청 예쁘다 ㅎㅎ",
            textEng: "For real… Oh wow, look at those people in hanbok! So pretty ☺️",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY7_3.mp3"
          },
          {
            text: "정말 예쁘네! 그러지 말고 우리도 한복 입고 돌아보러 가자 ㅎㅎ",
            textEng: "Totally! You know what? Let's rent hanbok too and explore!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8B%E1%85%A7%E1%84%92%E1%85%A2%E1%86%BC-%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC-/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '숙박 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Accommodation theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '숙박 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Accommodation theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
  },
  
  // 4. 문화 테마
  "문화": {
    "콘서트": {
      "초급":{
        "Day1": [
          {
            text: "우리 드디어 한국에서 직관하는 거 실화야? 분위기 진짜 대박이다!",
            textEng: "Are we actually watching this live in Korea? The vibe here is insane!",
            highlight: "분위기",
            highlightEng: "The vibe",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY1_1.mp3"
          },
          {
            text: "내 말이! 다들 벌써부터 에너지가 장난 아니다!",
            textEng: "Right? Everyone's energy is off the charts already!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "우리 굿즈 부스 가보자! 저 응원봉 진짜 예쁘더라!",
            textEng: "Let's check out the merch booth! That lightstick looked so pretty!",
            highlight: "응원봉",
            highlightEng: "lightstick",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY2_1.mp3"
          },
          {
            text: "응. 나 하나 살꺼야! 이거 들고 응원하면 완전 멋있을 것 같아!",
            textEng: "Yup, I'm definitely getting one! I bet it'll look so cool when we cheer!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "팬들이 다 같이 응원 구호 외치고 있어. 우리도 외워야겠다!",
            textEng: "Fans are chanting together! We should learn the chant too!",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY3_1.mp3"
          },
          {
            text: "당연하지! 빨리 따라 배워보자!",
            textEng: "For sure! Let's pick it up quick!",
            highlight: "당연하지",
            highlightEng: "For sure",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "와우.. 무대 연출이 미쳤네.. 영상도 조명도 너무 멋지게 만들었다!",
            textEng: "Wow… the stage production is crazy. The visuals and lighting are on point!",
            highlight: "무대 연출",
            highlightEng: "the stage production",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY4_1.mp3"
          },
          {
            text: "그러게. 한국 콘서트는 확실히 퀄리티가 다른 것 같아.",
            textEng: "Yeah, K-pop concerts are definitely next-level quality.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY4_2.mp3"
          },
          {
            text: "K-pop이 세계에서 히트하는 이유가 다 있다니까!",
            textEng: "There's a reason K-pop is a global hit!",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "와! 드디어 나왔다! 이제 꿈이야 생시야..",
            textEng: "OMG! They're finally here! Is this real life?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY5_1.mp3"
          },
          {
            text: "내가 지금 보고 있는 게 신인가? 왜 이렇게 빛이 나지..?",
            textEng: "Am I looking at an actual angel? Why are they glowing like that?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY5_2.mp3"
          },
          {
            text: "천국에 있는 것 같아. 이대로 시간이 멈췄으면 좋겠다..!",
            textEng: "Feels like I'm in heaven. I wish time would stop right now!",
            highlight: "천국",
            highlightEng: "heaven",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "다 같이 떼창하는 거 들려? 소리 장난 아니다!",
            textEng: "Can you hear everyone singing along? The crowd is wild!",
            highlight: "떼창",
            highlightEng: "singing along",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY6_1.mp3"
          },
          {
            text: "그러게. 우리도 따라 부르자 ㅎㅎ",
            textEng: "Let's join in too! haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY6_2.mp3"
          },
          {
            text: "좋아. 오늘을 위해서 가사 다 외우고 왔거든 ㅎㅎ",
            textEng: "Good thing I memorized all the lyrics for today! haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY6_3.mp3"
          },
          {
            text: "대박.. 나도 어제 밤새 외웠는데 ㅋㅋ",
            textEng: "Same! I stayed up all night learning them! haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "와 이 곡을 라이브로 듣는다고?",
            textEng: "Are we really hearing this song live?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY7_1.mp3"
          },
          {
            text: "미쳤다.. 게다가 특별 무대로 준비했네 ㅜㅜ",
            textEng: "I'm losing it… and they prepared it as a special stage! 😭",
            highlight: "미쳤다",
            highlightEng: "I'm losing it..",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY7_2.mp3"
          },
          {
            text: "팬들을 얼마나 사랑하는걸까..",
            textEng: "How much must they love their fans…",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY7_3.mp3"
          },
          {
            text: "역시 우리 오빠밖에 없어! 난 오빠의 영원한 팬이야!",
            textEng: "Only my fave could do this! I'll be their fan forever!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8F%E1%85%A9%E1%86%AB%E1%84%89%E1%85%A5%E1%84%90%E1%85%B3-/DAY7_4.mp3"
          }
        ]
      },
      "중급": {
        "Day1": [
          {
            text: '식당 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Restaurant theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '식당 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Restaurant theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
    "영화관": {
      "초급": {
        "Day1": [
          {
            text: "나 이 영화 얼마나 기대했는지 알아?",
            textEng: "Do you know how long I've been waiting for this movie?",
            highlight: "영화",
            highlightEng: "movie",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY1_1.mp3"
          },
          {
            text: "나도 엄청 기대 중! 평이 엄청 좋더라고.",
            textEng: "Me too! The reviews are amazing.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "우리 자리가 어디였지? E3번 맞아?",
            textEng: "What's our seat again? E3, right?",
            highlight: "자리",
            highlightEng: "seat",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY2_1.mp3"
          },
          {
            text: "응, 여기 맞아! 중간 자리라서 딱 좋네.",
            textEng: "Yup, here it is! Perfect spot in the middle.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "우리 뭐 먹을 거 사 가져갈까?",
            textEng: "Should we grab some snacks?",
            highlight: "우리",
            highlightEng: "we",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY3_1.mp3"
          },
          {
            text: "그래! 팝콘 카라멜 맛으로 하자 ㅎㅎ",
            textEng: "Yeah! Let's get caramel popcorn! haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "와 이번에 예고편 재밌는거 많다!",
            textEng: "Whoa, these trailers look good!",
            highlight: "예고편",
            highlightEng: "trailers",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY4_1.mp3"
          },
          {
            text: "그러게. 그 액션 영화도 재밌어 보인다!",
            textEng: "Totally. That action movie looks awesome!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY4_2.mp3"
          },
          {
            text: "나도 그거 궁금했어. 나중에 같이 보러 가자 ㅎㅎ",
            textEng: "I was curious about that one too. Let's watch it together later! haha",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "와 주인공 감정연기 봤어?",
            textEng: "Did you see the main character's emotional acting?",
            highlight: "주인공",
            highlightEng: "the main character's",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY5_1.mp3"
          },
          {
            text: "응 눈물이 글썽거리는게 너무 느껴지더라.",
            textEng: "Yeah, their eyes were all teary. So touching.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY5_2.mp3"
          },
          {
            text: "맞어. 이 배우 완전 재발견이야!",
            textEng: "Right? This actor's really showing a new side!",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "영화 어땠어?",
            textEng: "What did you think of the movie?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY6_1.mp3"
          },
          {
            text: "솔직히 나는 결말이 조금 아쉬웠어 ㅜ",
            textEng: "Honestly, I was kinda disappointed by the ending 😭",
            highlight: "결말",
            highlightEng: "the ending",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY6_2.mp3"
          },
          {
            text: "나도.. 엔딩이 좀 애매하지 않았어?",
            textEng: "Same… wasn't the ending a bit vague?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY6_3.mp3"
          },
          {
            text: "그니까! 좀더 해피엔딩이 였으면 좋았을듯.",
            textEng: "Exactly! A happier ending would've been better.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "나 사실 영화 보기전에 후기를 봤거든?",
            textEng: "I actually read some reviews before watching.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY7_1.mp3"
          },
          {
            text: "아 정말. 그럼 결말 알고있었겠네 ㅋㅋ",
            textEng: "Oh really? So you knew the ending? haha",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY7_2.mp3"
          },
          {
            text: "응 근데 결말 알면서 봐도 너무 재밌었어!",
            textEng: "Yeah, but even knowing it, I still enjoyed it!",
            highlight: "재밌었어",
            highlightEng: "enjoyed",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY7_3.mp3"
          },
          {
            text: "그건 정말 찐이네 ㅎㅎ 근데 스토리 정말 잘 썼더라.",
            textEng: "That's real dedication! haha And the storyline was really well done.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA-%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A5%E1%86%AB%E1%84%90%E1%85%B3+-%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB-/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '요리 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Cooking theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '요리 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Cooking theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
  },
  
  // 5. 드라마 테마
  "드라마": {
    "로맨스": {
      "초급": {
        "Day1": [
          {
            text: "오! 우리 또 만났네요? 우린 인연인가봐요",
            textEng: "Oh! We meet again? Guess we're meant to be.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY1_1.mp3"
          },
          {
            text: "그렇네요. 또 이렇게 만나게 될 줄은 몰랐어요.",
            textEng: "Yeah, I didn't think we'd run into each other like this.",
            highlight: "이렇게",
            highlightEng: "like this",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "우리.. 이거 마시면 사귀는거다?",
            textEng: "If we drink this… we're officially dating, okay?",
            highlight: "마시면",
            highlightEng: "drink",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY2_1.mp3"
          },
          {
            text: "이리 줘봐. 내가 마시게.",
            textEng: "Hand it over. I'll drink it 😆",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "아까는 미안했어. 제가 너무 예민했나 봐.",
            textEng: "Hey, I'm sorry about earlier. I think I overreacted.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY3_1.mp3"
          },
          {
            text: "그렇게 미안하면 뽀뽀 한번 해보던가.",
            textEng: "If you're really sorry, how about a kiss? 😜",
            highlight: "뽀뽀",
            highlightEng: "a kiss",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "저기, 이거 어제 행사에서 떨어뜨리셨죠?",
            textEng: "Hey, you dropped this at the event yesterday, right?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY4_1.mp3"
          },
          {
            text: "아, 잃어버린 줄 알았는데.. 감사합니다.  제가 그때 정신이 없었어요.",
            textEng: "Oh wow, I thought I lost it… Thank you! I was so out of it that day.",
            highlight: "감사합니다",
            highlightEng: "Thank you",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY4_2.mp3"
          },
          {
            text: "다행이에요. 근데 혹시 저 어디서 본 적 없으세요?",
            textEng: "No worries. But… don't you think we've seen each other before?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "난 네가 힘든게 싫어. 요즘 너 때문에 잠이 안와.",
            textEng: "I hate seeing you struggle. I can't even sleep these days because of you.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY5_1.mp3"
          },
          {
            text: "네가 뭔데 나를 걱정해? 그냥 나 내버려 둬.",
            textEng: "Why do you even care? Just leave me alone.",
            highlight: "내버려 둬",
            highlightEng: "leave",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY5_2.mp3"
          },
          {
            text: "나도 모르게 자꾸 신경이 쓰인다고. 혹시 나.. 너 좋아하니?",
            textEng: "I don't know… I just keep thinking about you. Wait… do I like you?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "\"너 요즘 나를 피하는거 같아. 왜 그래?\"",
            textEng: "You've been avoiding me lately. What's going on?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY6_1.mp3"
          },
          {
            text: "그런거 아니야. 그냥 요즘 내 마음이 복잡해서 그래.",
            textEng: "It's not like that… My mind's just a mess these days.",
            highlight: "마음",
            highlightEng: "mind's",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY6_2.mp3"
          },
          {
            text: "내가 너 힘들게 하는거야? 싫으면 싫다고 말해줘.",
            textEng: "Am I the problem? Just tell me if you don't want me around.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY6_3.mp3"
          },
          {
            text: "그래. 나 너 싫어해. 더 이상 나 귀찮게 하지마.",
            textEng: "Yeah. I don't like you. Stop bothering me.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "나 이대로 시간이 멈췄으면 좋겠어.",
            textEng: "I wish time would stop right now.",
            highlight: "시간",
            highlightEng: "time",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY7_1.mp3"
          },
          {
            text: "나도.. 우리 앞으로 지금이대로 행복할 수 있는거 맞지?",
            textEng: "Me too… Do you think we can stay this happy forever?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY7_2.mp3"
          },
          {
            text: "그럼. 이제 아무 걱정 안해도 돼. 내가 널 지켜줄께.",
            textEng: "Of course. You don't have to worry anymore. I'll always protect you.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY7_3.mp3"
          },
          {
            text: "우리 행복하자. 지금 처럼 영원도록..!",
            textEng: "Let's be happy, just like this… forever..!",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%85%E1%85%A9%E1%84%86%E1%85%A2%E1%86%AB%E1%84%89%E1%85%B3-/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '영화 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Movie theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '영화 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Movie theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
    "스릴러": {
      "초급": {
        "Day1": [
          {
            text: "너... 뭔가 숨기고 있는 거 맞지?",
            textEng: "Hey… you're hiding something, aren't you?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY1_1.mp3"
          },
          {
            text: "뭔 소리야. 뭘 숨긴다고 그래.",
            textEng: "What? What would I even be hiding?",
            highlight: "숨긴다고",
            highlightEng: "hiding",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY1_2.mp3"
          }
        ],
        "Day2": [
          {
            text: "빨리 달리자! 곧 따라잡힐 거야.",
            textEng: "Run! They're catching up!",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY2_1.mp3"
          },
          {
            text: "잠깐만.. 우리 여기 방에 숨어있자.",
            textEng: "Wait… let's hide in this room for a sec.",
            highlight: "잠깐만",
            highlightEng: "Wait",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY2_2.mp3"
          }
        ],
        "Day3": [
          {
            text: "너 전화 울리는데? 받아봐.",
            textEng: "Your phone's ringing. Pick it up.",
            highlight: "전화",
            highlightEng: "phone's",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY3_1.mp3"
          },
          {
            text: "모르는 번호인데? \"여보세요?\"",
            textEng: "It's an unknown number. \"Hello?\"",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY3_2.mp3"
          }
        ],
        "Day4": [
          {
            text: "누구야? 너 얼굴이 왜 창백해졌어?",
            textEng: "Who was it? Why do you look so pale?",
            highlight: "누구야?",
            highlightEng: "who",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY4_1.mp3"
          },
          {
            text: "여기 있었던 파일이 없어졌어. 너 봤어?",
            textEng: "The file that was here… it's gone. Did you see it?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY4_2.mp3"
          },
          {
            text: "아니, 너 어제 책상 위에 두지 않았어? 이상하네..",
            textEng: "No, didn't you leave it on your desk yesterday? That's weird…",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY4_3.mp3"
          }
        ],
        "Day5": [
          {
            text: "방금 복도에서 무슨 발 소리 들리지 않았어?",
            textEng: "Wait, did you hear that? Sounded like footsteps in the hallway.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY5_1.mp3"
          },
          {
            text: "엥? 난 아무것도 안들렸는데? 잘 못들었겠지.",
            textEng: "Huh? I didn't hear anything. Maybe you imagined it?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY5_2.mp3"
          },
          {
            text: "아니야.. 나 분명히 누가 걸어가는 소리가 들렸다고..!",
            textEng: "No, I swear! Someone was walking out there…",
            highlight: "분명히",
            highlightEng: "swear",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY5_3.mp3"
          }
        ],
        "Day6": [
          {
            text: "와 사진 좀 봐. 우리 대학교 MT에서 찍은거다.",
            textEng: "Look at this pic! It's from our college retreat.",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY6_1.mp3"
          },
          {
            text: "얼굴 젊은거봐. 잠깐.. 너 뒤에 뭐가 보이는데?",
            textEng: "Wow, we looked so young. Wait… what's that behind you?",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY6_2.mp3"
          },
          {
            text: "어.. 이게 뭐지? 무슨 얼굴 같이 보이는데..?",
            textEng: "Huh..? What is that? It kinda looks like a face…",
            highlight: "얼굴",
            highlightEng: "a face",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY6_3.mp3"
          },
          {
            text: "야.. 이거 걔 아니야? 우리 어렸을때 같이 놀았는데 사고로 죽은 친구 있잖아.",
            textEng: "Wait… isn't that him? You remember the friend we used to play with when we were kids…? the one who died in that accident.",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY6_4.mp3"
          }
        ],
        "Day7": [
          {
            text: "여기가 그 유명한 귀신 나오는 터널이야?",
            textEng: "Is this the haunted tunnel everyone talks about?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY7_1.mp3"
          },
          {
            text: "응. 여기 중간 지점에서 귀신이 나온데..!",
            textEng: "Yeah, they say ghosts appear right in the middle of it…",
            highlight: "",
            highlightEng: "",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY7_2.mp3"
          },
          {
            text: "난 안믿지만..일단 가보자! 근데 왜 이렇게 사람이 없지?",
            textEng: "I don't believe in that, but let's check it out. Wait, why is no one around?",
            highlight: "",
            highlightEng: "",
            background: "#FFFFFF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY7_3.mp3"
          },
          {
            text: "앞에 누구 계시네. 잠깐 저 사람.. 다리가 없는데?",
            textEng: "There's someone up ahead. Hold on… that person… doesn't have legs?!",
            highlight: "사람",
            highlightEng: "pearson",
            background: "#B3D9FF",
            textColor: "textPrimary",
            audioUrl: "https://nissiaudio.s3.ap-northeast-2.amazonaws.com/10.+%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%86%E1%85%A1-%E1%84%89%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5-/DAY7_4.mp3"
          }
        ]
      }
      ,
      "중급": {
        "Day1": [
          {
            text: '음악 테마 중급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Music theme Intermediate Day1.',
            highlight: '중급',
            background: '#FFFACD',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio5.mp3',
          },
        ],
      },
      "고급": {
        "Day1": [
          {
            text: '음악 테마 고급 Day1 예시 문장입니다.',
            textEng: 'This is a sample sentence for Music theme Advanced Day1.',
            highlight: '고급',
            background: '#F0E68C',
            textColor: 'textPrimary',
            audioUrl: 'https://nissiaudio.s3.ap-northeast-2.amazonaws.com/base/example_audio3.mp3',
          },
        ],
      },
    },
  },
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // 필요하다면 메시지를 추가/수정/삭제 하는 reducer를 작성할 수 있음
    // e.g., addMessage, removeMessage 등
    addMessage: (state, action) => {
      const { theme, category, difficulty, day, message } = action.payload;
      
      // 해당 경로가 없으면 생성
      if (!state[theme]) {
        state[theme] = {};
      }
      if (!state[theme][category]) {
        state[theme][category] = {};
      }
      if (!state[theme][category][difficulty]) {
        state[theme][category][difficulty] = {};
      }
      if (!state[theme][category][difficulty][day]) {
        state[theme][category][difficulty][day] = [];
      }
      
      // 메시지 추가
      state[theme][category][difficulty][day].push(message);
    },
    
    removeMessage: (state, action) => {
      const { theme, category, difficulty, day, index } = action.payload;
      
      // 해당 경로가 존재하는지 확인
      if (state[theme]?.[category]?.[difficulty]?.[day]?.length > index) {
        // 해당 인덱스의 메시지 제거
        state[theme][category][difficulty][day].splice(index, 1);
      }
    },
  },
});

// Action creators 내보내기
export const { addMessage, removeMessage } = messagesSlice.actions;

// 기본 상태 선택자
const selectMessagesState = (state: any) => state.messages;

// (1) 메모이제이션된 선택자: 메시지 선택
export const selectMessagesByParams = (
  theme: string,
  category: string,
  difficulty: string,
  day: string
) => createSelector(
  [selectMessagesState],
  (messages) => messages?.[theme]?.[category]?.[difficulty]?.[day] || []
);

// (2) 전체 messages를 통째로 가져오는 셀렉터 (필요하면 사용)
export const selectAllMessages = createSelector(
  [selectMessagesState],
  (messages) => messages
);

// (3) 특정 테마의 모든 카테고리 가져오기 - 메모이제이션 적용
export const makeSelectCategoriesByTheme = () => {
  const selectCategoriesByThemeInternal = createSelector(
    [selectMessagesState, (_state: any, theme: string) => theme],
    (messages, theme) => messages?.[theme] ? Object.keys(messages[theme]) : []
  );
  
  return (theme: string) => (state: any) => selectCategoriesByThemeInternal(state, theme);
};

// 실제 사용할 선택자 생성
export const selectCategoriesByTheme = makeSelectCategoriesByTheme();

// (4) 특정 테마와 카테고리의 모든 난이도 가져오기 - 메모이제이션 적용
export const makeSelectDifficultiesByCategoryAndTheme = () => {
  const selectDifficultiesByCategoryAndThemeInternal = createSelector(
    [
      selectMessagesState,
      (_state: any, theme: string, category: string) => ({ theme, category })
    ],
    (messages, { theme, category }) => 
      messages?.[theme]?.[category] ? Object.keys(messages[theme][category]) : []
  );
  
  return (theme: string, category: string) => (state: any) => 
    selectDifficultiesByCategoryAndThemeInternal(state, theme, category);
};

// 실제 사용할 선택자 생성
export const selectDifficultiesByCategoryAndTheme = makeSelectDifficultiesByCategoryAndTheme();

// (5) 특정 테마, 카테고리, 난이도의 모든 Day 가져오기 - 메모이제이션 적용
export const makeSelectDaysByThemeCategoryAndDifficulty = () => {
  const selectDaysByThemeCategoryAndDifficultyInternal = createSelector(
    [
      selectMessagesState,
      (_state: any, theme: string, category: string, difficulty: string) => 
        ({ theme, category, difficulty })
    ],
    (messages, { theme, category, difficulty }) => 
      messages?.[theme]?.[category]?.[difficulty] ? 
        Object.keys(messages[theme][category][difficulty]) : []
  );
  
  return (theme: string, category: string, difficulty: string) => (state: any) => 
    selectDaysByThemeCategoryAndDifficultyInternal(state, theme, category, difficulty);
};

// 실제 사용할 선택자 생성
export const selectDaysByThemeCategoryAndDifficulty = makeSelectDaysByThemeCategoryAndDifficulty();

export default messagesSlice.reducer;