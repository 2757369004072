import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserExists } from '../../AuthService/cognitoAdmin';
import { signUp, signIn, getCurrentUser } from '../../AuthService/AuthService';
import { AuthContext } from './AuthContext';

const KakaoCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setUser, setSession } = useContext(AuthContext);
  const kakaoDefaultPassword = "KakaoSignInPassword1!";

  // useRef를 사용하여 useEffect가 중복 실행되는 것을 방지합니다.
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const handleKakaoLogin = async () => {
      // URL 쿼리에서 인가 코드 추출 (예: ?code=...)
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');

      if (!code) {
        alert('카카오 인가 코드를 찾을 수 없습니다.');
        navigate('/signin');
        return;
      }

      try {
        // 인가 코드를 이용해 액세스 토큰 요청
        const tokenResponse = await fetch('https://kauth.kakao.com/oauth/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: process.env.REACT_APP_KAKAO_CLIENT_ID || '',
            redirect_uri: window.location.origin + '/kakao-callback',
            code: code,
          }),
        });

        if (!tokenResponse.ok) {
          throw new Error('토큰을 가져오는데 실패했습니다.');
        }

        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;

        if (!accessToken) {
          alert('카카오 액세스 토큰을 받지 못했습니다.');
          navigate('/signin');
          return;
        }

        // 카카오 API를 호출하여 사용자 정보 가져오기
        const userResponse = await fetch('https://kapi.kakao.com/v2/user/me', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (!userResponse.ok) {
          throw new Error('카카오에서 사용자 정보를 가져오지 못했습니다.');
        }

        const userInfo = await userResponse.json();
        // kakao_account 안에 이메일 정보, properties에 닉네임 등이 포함되어 있습니다.
        const email = userInfo.kakao_account && userInfo.kakao_account.email;
        const name = userInfo.properties && userInfo.properties.nickname;
        console.log('카카오 사용자 정보:', userInfo);

        if (!email) {
          alert('카카오 계정에 이메일 정보가 없습니다.');
          navigate('/signin');
          return;
        }

        // Cognito에 동일 이메일로 가입된 회원이 있는지 확인
        const exists = await checkUserExists(email);

        if (exists) {
          try {
            const signInResult = await signIn(email, kakaoDefaultPassword);
            const currentUser = getCurrentUser();
            setUser(currentUser);
            setSession(signInResult);
            navigate('/');
          } catch (loginError) {
            console.error('자동 로그인 실패:', loginError);
            alert('자동 로그인이 실패했습니다. 기존 가입한 경로로 로그인해주세요.');
            navigate('/signin');
          }
        } else {
          // 신규 회원가입 처리 후 자동 로그인(가입 확인 페이지로 이동)
          await signUp(email, kakaoDefaultPassword, name || '', '', '');
          console.log('신규 회원가입 처리 후 자동 로그인');
          navigate('/confirm-signup', { state: { email } });
        }
      } catch (error) {
        console.error('카카오 로그인 처리 중 오류 발생:', error);
        navigate('/signin');
      }
    };

    handleKakaoLogin();
  }, [navigate, setUser, setSession, kakaoDefaultPassword]);

  return <div></div>;
};

export default KakaoCallback;
