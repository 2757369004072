import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Avatar,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  SwipeableDrawer,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SmallCharacter from '../../svg/SmallCharacter';
import { getUserAttributes, updateUserAttributes } from '../../AuthService/AuthService';
import { AuthContext } from '../auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setProfileData, updateProfileImage } from '../../store/profileSlice';

const ProfileEditPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const profileData = useSelector((state: RootState) => state.profile);

  const [isEditing, setIsEditing] = useState(false);

  const { logout } = useContext(AuthContext);

  const [openBottomSheet, setOpenBottomSheet] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null); // 파일 입력 참조
  const cameraInputRef = useRef<HTMLInputElement>(null); // 카메라 입력 참조

  const [isUploading, setIsUploading] = useState(false); // 업로드 상태

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        dispatch(setProfileData({
          name: attributes['custom:name'] || '',
          nickname: attributes['custom:nickname'] || '',
          introduction: attributes['custom:introduction'] || '',
          image: attributes['custom:picture'] || '/path/to/profile.jpg',
          dream1: attributes['custom:dream1'] || '',
          dream2: attributes['custom:dream2'] || '',
          dream3: attributes['custom:dream3'] || '',
          dream4: attributes['custom:dream4'] || '',
          dream5: attributes['custom:dream5'] || '',
          dream6: attributes['custom:dream6'] || '',
          dream7: attributes['custom:dream7'] || '',
          dreamtext: attributes['custom:dreamtext'] || '',
        }));
      } catch (error) {
        console.error('사용자 속성 가져오기 오류:', error);
        alert('사용자 정보를 불러오는 데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttributes();
  }, [dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setProfileData({
      ...profileData,
      [name]: value,
    }));
  };

  // 프로필 이미지 변경 핸들러
  const handleChangeImage = () => {
    setOpenBottomSheet(true);
  };

  const handleSelectFromLibrary = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setOpenBottomSheet(false);
  };

  const handleTakePhoto = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
    setOpenBottomSheet(false);
  };

  const handleDeletePhoto = () => {
    // 프로필 사진 삭제 로직 구현
    const defaultImage = '/path/to/default_profile.jpg';
    dispatch(updateProfileImage(defaultImage));
    alert('프로필 사진이 삭제되었습니다.');
    setOpenBottomSheet(false);
  };

  // **Cloudinary Configuration**
  const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
  const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

  // **Cloudinary Upload Function**
  const uploadImageToCloudinary = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET || '');

    try {
      const response = await fetch(CLOUDINARY_URL, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Cloudinary 업로드 실패');
      }

      const data = await response.json();
      // Use secure_url to ensure HTTPS
      return data.secure_url;
    } catch (error) {
      console.error('Cloudinary 업로드 오류:', error);
      throw new Error('이미지 업로드에 실패했습니다.');
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        // Cloudinary에 이미지 업로드
        const imageUrl = await uploadImageToCloudinary(file);

        // 프로필 데이터 상태 업데이트
        dispatch(updateProfileImage(imageUrl));

        // custom:picture 속성 업데이트
        const attributesToUpdate: { [key: string]: string } = {
          'custom:picture': imageUrl,
        };

        await updateUserAttributes(attributesToUpdate);
      } catch (error) {
        if (error instanceof Error) {
          alert(error.message);
        } else {
          alert('An unknown error occurred.');
        }
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleEditSave = async () => {
    if (isEditing) {
      // 입력값 검증
      if (profileData.name.trim() === '') {
        alert('이름을 입력해주세요.');
        return;
      }
      if (profileData.introduction.trim() === '') {
        alert('소개를 입력해주세요.');
        return;
      }

      try {
        const attributesToUpdate: { [key: string]: string } = {
          'custom:name': profileData.name,
          'custom:nickname': profileData.nickname,
          'custom:introduction': profileData.introduction,
          'custom:picture': profileData.image,
        };

        await updateUserAttributes(attributesToUpdate);
        alert('프로필이 성공적으로 저장되었습니다.');
      } catch (error) {
        console.error('사용자 속성 업데이트 오류:', error);
        alert('프로필 저장에 실패했습니다. 다시 시도해주세요.');
      }
    }
    setIsEditing(!isEditing);
  };

  const [open, setOpen] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);

  const handleDeleteAccount = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    setOpen(false);
    setOpenComplete(true);
  };

  const handleCloseComplete = () => {
    setOpenComplete(false);
    navigate('/');
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '55px',
      }}
    >
      {!isLoading ? (
        <Container>
          <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar>
              <IconButton edge="start" onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                프로필 편집
              </Typography>
              <Button color="primary" onClick={handleEditSave}>
                {isEditing ? '저장' : '수정'}
              </Button>
            </Toolbar>
          </AppBar>

          {/* 프로필 이미지 및 수정 버튼 */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Avatar src={profileData.image} sx={{ width: 120, height: 120 }} />
            <Button onClick={handleChangeImage} sx={{ mt: 2 }}>
              사진 수정
            </Button>
          </Box>

          {/* 숨겨진 파일 입력 요소 */}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />

          <input
            type="file"
            accept="image/*"
            capture="user"
            style={{ display: 'none' }}
            ref={cameraInputRef}
            onChange={handleFileChange}
          />

          {/* 업로드 중 로딩 인디케이터 */}
          {isUploading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}

          {/* 이름, 닉네임, 소개 입력 필드 */}
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              label="이름"
              name="name"
              value={profileData.name}
              onChange={handleChange}
              disabled={!isEditing}
              placeholder="이름을 작성해주세요."
              error={isEditing && profileData.name.trim() === ''}
              helperText={
                isEditing && profileData.name.trim() === '' ? '이름을 작성해주세요.' : ''
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="닉네임"
              name="nickname"
              value={profileData.nickname}
              onChange={handleChange}
              disabled={!isEditing}
              placeholder="닉네임을 작성해주세요."
              margin="normal"
            />
            <TextField
              fullWidth
              label="소개"
              name="introduction"
              value={profileData.introduction}
              onChange={handleChange}
              disabled={!isEditing}
              placeholder="소개를 작성해주세요."
              multiline
              rows={4}
              error={isEditing && profileData.introduction.trim() === ''}
              helperText={
                isEditing && profileData.introduction.trim() === '' ? '소개를 작성해주세요.' : ''
              }
              margin="normal"
            />
          </Box>
          <Box
            sx={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ marginBottom: '8px' }}
              onClick={handleLogout}
            >
              로그아웃
            </Button>
            <Button variant="text" color="error" onClick={handleDeleteAccount}>
              회원탈퇴
            </Button>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                overflow: 'visible',
              },
            }}
          >
            <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }}>
              정말 회원탈퇴를 하시겠습니까?
              <IconButton
                aria-label="close"
                sx={{
                  position: 'absolute',
                  right: '-55px',
                  top: '-55px',
                }}
                onClick={handleClose}
              >
                <SmallCharacter />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>우리의 이별은 아쉽지만, Nissi는</Typography>
              <Typography gutterBottom>
                {profileData.nickname}님의 앞날을 쭉 응원할게요.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                아니오
              </Button>
              <Button onClick={handleConfirmDelete} color="error">
                예
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openComplete}
            onClose={handleCloseComplete}
            PaperProps={{
              sx: {
                overflow: 'visible',
              },
            }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>회원탈퇴가 완료되었습니다 ㅠㅠ</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseComplete} color="primary">
                확인
              </Button>
            </DialogActions>
          </Dialog>

          {/* 바텀시트 추가 */}
          <SwipeableDrawer
            anchor="bottom"
            open={openBottomSheet}
            onClose={() => setOpenBottomSheet(false)}
            onOpen={() => {}}
          >
            <Box
              sx={{
                padding: 2,
              }}
            >
              <Button fullWidth onClick={handleSelectFromLibrary}>
                라이브러리에서 선택
              </Button>
              <Button fullWidth onClick={handleTakePhoto}>
                사진촬영
              </Button>
              <Button fullWidth onClick={handleDeletePhoto} color="error">
                삭제
              </Button>
            </Box>
          </SwipeableDrawer>
        </Container>
      ) : null}
    </Box>
  );
};

export default ProfileEditPage;
