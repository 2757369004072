import React, { useEffect } from 'react';

declare global {
  interface Window {
    TossPayments: any;
    paypal: any;
  }
}

const Payment = () => {
  useEffect(() => {
    // Toss Payments 라이브러리 스크립트를 동적으로 로딩합니다.
    const tossScript = document.createElement('script');
    tossScript.src = 'https://js.tosspayments.com/v1';
    tossScript.async = true;
    tossScript.onload = () => console.log('TossPayments 스크립트 로드 완료');
    tossScript.onerror = () => console.error('TossPayments 스크립트 로드 실패');
    document.body.appendChild(tossScript);

    return () => {
      document.body.removeChild(tossScript);
    };
  }, []);

  const handleRecurringCardPayment = () => {
    console.log('정기결제 버튼 클릭');

    // 개발/테스트용 클라이언트 키 (실제 운영 시 실제 키로 변경)
    const clientKey = process.env.REACT_APP_TOSS_CLIENT_ID;
    // 고객을 구분할 수 있는 고유 식별자 (DB에 저장되어 있어야 함)
    const customerKey = 'nissi'; // 실제 서비스에서는 고유한 값으로 대체

    if (window.TossPayments) {
      // 정기결제를 위해 빌링키 발급 요청
      window.TossPayments(clientKey).requestBillingAuth('카드', {
        customerKey,
        successUrl: 'https://your-app-url.com/payment-success',
        failUrl: 'https://your-app-url.com/payment-fail',
      });
    } else {
      console.error('TossPayments 라이브러리가 로드되지 않았습니다.');
    }
  };

  const handlePaypalPayment = () => {
    console.log('페이팔 버튼 클릭');
    // PayPal SDK를 동적으로 로딩 후, 구독 버튼을 렌더링합니다.
    if (!window.paypal) {
      const paypalScript = document.createElement('script');
      paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
      paypalScript.async = true;
      paypalScript.onload = () => {
        console.log('PayPal 스크립트 로드 완료');
        window.paypal.Buttons({
          createSubscription: function (data: any, actions: { subscription: { create: (arg0: { plan_id: string | undefined; }) => any; }; }) {
            return actions.subscription.create({
              'plan_id': process.env.REACT_APP_PAYPAL_PLAN_ID,
            });
          },
          onApprove: function (data: { subscriptionID: any; }, actions: any) {
            console.log('구독 완료, subscriptionID: ', data.subscriptionID);
          },
          onError: function (err: any) {
            console.error('구독 생성 중 오류 발생: ', err);
          },
        }).render('#paypal-button-container');
      };
      paypalScript.onerror = () => {
        console.error('PayPal 스크립트 로드 실패');
      };
      document.body.appendChild(paypalScript);
    } else {
      // 이미 로드된 경우 바로 버튼을 렌더링합니다.
      window.paypal.Buttons({
        createSubscription: function (data: any, actions: { subscription: { create: (arg0: { plan_id: string | undefined; }) => any; }; }) {
          return actions.subscription.create({
            'plan_id': process.env.REACT_APP_PAYPAL_PLAN_ID,
          });
        },
        onApprove: function (data: { subscriptionID: any; }, actions: any) {
          console.log('구독 완료, subscriptionID: ', data.subscriptionID);
        },
        onError: function (err: any) {
          console.error('구독 생성 중 오류 발생: ', err);
        },
      }).render('#paypal-button-container');
    }
  };

  const handleApplePay = () => {
    console.log('애플페이 버튼 클릭');
    // 애플페이 결제 로직 추가 (Apple Pay on the Web은 주로 Safari에서 지원하며, 별도의 SDK/API가 필요합니다)
    // 애플의 가이드 문서를 참고하여 구현하시기 바랍니다.
  };

  return (
    <div>
      <h1>Payment</h1>
      <p>Payment page</p>
      <button onClick={handleRecurringCardPayment}>국내정기결제 카드 등록</button>
      <button onClick={handlePaypalPayment}>페이팔</button>
      <button onClick={handleApplePay}>애플페이</button>
      {/* PayPal 버튼이 렌더링될 영역 */}
      <div id="paypal-button-container" style={{ marginTop: '20px' }}></div>
    </div>
  );
};

export default Payment;

