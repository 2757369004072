import React, { useRef, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMessagesByParams } from '../../store/messagesSlice';
import Layout from '../../components/Layout';
import {
  Container,
  Box,
  Typography,
  Button,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Listening: React.FC = () => {
  // URL 파라미터로 dayId ("1", "2", "3" 등)
  const { dayId } = useParams<{ dayId: string }>();
  const location = useLocation();
  
  // URL 쿼리 파라미터 추출
  const queryParams = new URLSearchParams(location.search);
  
  // 쿼리 파라미터로부터 theme, category, difficulty를 가져오거나 기본값 설정
  const themeParam = queryParams.get('theme') || '일상';
  const categoryParam = queryParams.get('category') || '친구';
  const difficultyParam = queryParams.get('difficulty') || '초급';

  const dayKey = `Day${dayId}`;
  
  // 새로운 데이터 구조에 맞게 selectMessagesByParams 호출
  const messages = useSelector(selectMessagesByParams(
    themeParam, 
    categoryParam, 
    difficultyParam, 
    dayKey
  ));

  // 오디오 재생 상태
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // 오디오 재생 핸들러
  const handlePlay = (url: string) => {
    if (isPlaying) return;

    const audio = new Audio(url);
    audioRef.current = audio;
    setIsPlaying(true);

    audio
      .play()
      .catch((error) => {
        console.error('Audio play error:', error);
        setIsPlaying(false);
      });

    audio.addEventListener('ended', () => {
      setIsPlaying(false);
    });
  };

  // --- 하이라이트 처리 유틸 ---
  // 문장 내 특정 단어(문구) highlightText를 빨간색으로 감싸주는 함수
  const highlightSentence = (
    sentence: string,
    highlightText: string | undefined,
    color: string
  ) => {
    if (!highlightText || !sentence.includes(highlightText)) {
      // 하이라이트 대상이 없으면 그냥 반환
      return (
        <Typography
          component="span"
          variant="body1"
          sx={{ display: 'inline' }}
          color={color}
        >
          {sentence}
        </Typography>
      );
    }

    // highlightText의 인덱스 찾기
    const index = sentence.indexOf(highlightText);
    const before = sentence.substring(0, index);
    const after = sentence.substring(index + highlightText.length);
    
    return (
      <Typography
        component="span"
        variant="body1"
        sx={{ display: 'inline' }}
        color={color}
      >
        {before}
        <Typography component="span" sx={{ color: 'red', fontWeight: 'bold' }}>
          {highlightText}
        </Typography>
        {after}
      </Typography>
    );
  };

  const backPage = () => {
    window.history.back();
  };

  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '55px',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            height: 'calc(100vh - 155px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8,
            pb: 8,
            pl: 2.5,
            pr: 2.5,
          }}
        >
          {/* 상단 헤더: 뒤로가기, TITLE, 알림 */}
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box onClick={backPage} sx={{ cursor: 'pointer', color: 'black' }}>
              <ArrowBackIosNewIcon sx={{ fontSize: 24 }} />
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {`DAY ${dayId}`}
            </Typography>
            <NotificationsNoneIcon sx={{ fontSize: 24 }} />
          </Box>

          {messages.map((msg: any, idx: number) => {
            const isOdd = (idx + 1) % 2 === 1;
            const highlightText = msg.highlight;

            const bubbleContainerStyle = {
              mt: idx === 0 ? 8 : 4,
              width: '100%',
              display: 'flex',
              position: 'relative' as const,
              justifyContent: isOdd ? 'flex-start' : 'flex-end',
              ...(isOdd ? { left: '-5px' } : { right: '-5px' }),
            };

            return (
              <Box key={idx} sx={bubbleContainerStyle}>
                <Box
                  sx={{
                    // 기존 말풍선 스타일
                    backgroundColor: msg.background,
                    borderRadius: '30px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    p: 3,
                    display: 'flex',
                    alignItems: 'flex-start',
                    maxWidth: '100%',
                    position: 'relative',

                    // 말풍선 꼬리
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 16,
                      width: 0,
                      height: 0,
                      borderStyle: 'solid',

                      // 왼쪽 말풍선일 때( isOdd === true )
                      ...(isOdd
                        ? {
                            left: -7,
                            borderWidth: '10px 10px 10px 0',
                            borderColor: `transparent ${msg.background} transparent transparent`,
                          }
                        : {
                            // 오른쪽 말풍선일 때( isOdd === false )
                            right: -7,
                            borderWidth: '10px 0 10px 10px',
                            borderColor: `transparent transparent transparent ${msg.background}`,
                          }),
                    },
                  }}
                >
                  <VolumeUpIcon
                    sx={{ mr: 1, mt: 0.5, cursor: 'pointer' }}
                    onClick={() => handlePlay(msg.audioUrl)}
                  />

                  {/* 텍스트 렌더링: 수정된 정규식으로 문장 분리 처리 */}
                  <Box sx={{ lineHeight: 1.6, wordBreak: 'keep-all' }}>
                    {(() => {
                      // 문장을 분리하는 개선된 방법
                      // 텍스트를 직접 분석하는 함수로 문장 분리
                      function splitSentences(text: string | any[]) {
                        let result = [];
                        let currentSentence = "";
                        let i = 0;
                        
                        while (i < text.length) {
                          currentSentence += text[i];
                          
                          // 단일 마침표 + 공백인지 확인 (연속된 마침표는 아님)
                          if (text[i] === '.' && 
                              (i === 0 || text[i-1] !== '.') && 
                              (i === text.length-1 || text[i+1] !== '.') && 
                              (i < text.length-1 && /\s/.test(text[i+1]))) {
                            
                            result.push(currentSentence);
                            currentSentence = "";
                            
                            // 공백 건너뛰기
                            while (i < text.length-1 && /\s/.test(text[i+1])) {
                              i++;
                            }
                          }
                          
                          i++;
                        }
                        
                        // 마지막 문장 추가
                        if (currentSentence) {
                          result.push(currentSentence);
                        }
                        
                        return result;
                      }
                      
                      // 수정된 함수로 문장 분리
                      const sentences = splitSentences(msg.text)
                        .filter((s: string) => s.trim().length > 0);  // 빈 문자열 제거

                      // 2) 두 문장씩 묶어서 렌더링
                      const elements: JSX.Element[] = [];

                      for (let i = 0; i < sentences.length; i += 2) {
                        const sentence1 = sentences[i];
                        const sentence2 = sentences[i + 1] ?? null;

                        if (!sentence2) {
                          // sentence2가 없으면 (홀수개) 문장1만 표시
                          elements.push(
                            <React.Fragment key={`${idx}-${i}`}>
                              {highlightSentence(
                                sentence1,
                                highlightText,
                                msg.textColor
                              )}
                              <br />
                            </React.Fragment>
                          );
                        } else {
                          // 두 문장이 모두 있을 때
                          // 공백을 추가해서 문장을 조합
                          const combinedStr = `${sentence1} ${sentence2}`;

                          if (combinedStr.length > 20) {
                            // 20자 초과 시 첫 번째 문장과 두 번째 문장을 분리하여 렌더링
                            elements.push(
                              <React.Fragment key={`${idx}-${i}-over`}>
                                {highlightSentence(
                                  sentence1,
                                  highlightText,
                                  msg.textColor
                                )}
                                <br />
                                {highlightSentence(
                                  sentence2,
                                  highlightText,
                                  msg.textColor
                                )}
                                <br />
                              </React.Fragment>
                            );
                          } else {
                            // 20자 이하이면 한 줄에 두 문장 표시 (공백 추가)
                            elements.push(
                              <React.Fragment key={`${idx}-${i}-under`}>
                                {highlightSentence(
                                  sentence1,
                                  highlightText,
                                  msg.textColor
                                )}{' '}
                                {highlightSentence(
                                  sentence2,
                                  highlightText,
                                  msg.textColor
                                )}
                                <br />
                              </React.Fragment>
                            );
                          }
                        }
                      }

                      return elements;
                    })()}
                  </Box>
                </Box>
              </Box>
            );
          })}

          {/* 하단 NEXT 버튼 */}
          <Box
            sx={{
              position: 'absolute',
              bottom: '40px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Link
              to={`/plan/speaking/${dayId}?theme=${encodeURIComponent(
                themeParam
              )}&category=${encodeURIComponent(
                categoryParam
              )}&difficulty=${encodeURIComponent(difficultyParam)}`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#9D76DD',
                  borderRadius: '40px',
                  padding: '10px 20px',
                  color: '#FFF',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  '&:hover': {
                    backgroundColor: '#B28FF0',
                  },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                NEXT
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export default Listening;