import React from 'react';
import Layout from '../components/Layout';
import { Typography, Container,Box } from '@mui/material';

const Profile: React.FC = () => {
  return (
    <Layout>
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="h4" component="h1" gutterBottom>
          Profile
        </Typography>
      </Container>
    </Box>
  </Layout>
  );
};

export default Profile;