import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './profileSlice';
import messagesReducer from './messagesSlice';
import speakingReducer from './speakingSlice';

const store = configureStore({
  reducer: {
    profile: profileReducer,
    messages: messagesReducer,
    speaking: speakingReducer,
    // 다른 리듀서가 있다면 여기에 추가
  },
});

// 타입 정의
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
