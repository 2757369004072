import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserExists } from '../../AuthService/cognitoAdmin';
import { signUp, signIn, getCurrentUser } from '../../AuthService/AuthService';
import { AuthContext } from './AuthContext';

const GoogleCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setUser, setSession } = useContext(AuthContext);
  const googleDefaultPassword = "GoogleSignInPassword1!";

  // useRef를 사용하여 useEffect가 중복 실행되는 것을 방지합니다.
  const effectRan = useRef(false);

  useEffect(() => {
    // 이미 실행되었다면 실행하지 않음
    if (effectRan.current) return;
    effectRan.current = true;

    const handleGoogleLogin = async () => {
      // URL 해시에서 파라미터 추출 (예: #access_token=...&token_type=Bearer&expires_in=3600)
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.slice(1));
      const accessToken = params.get('access_token');

      if (!accessToken) {
        alert('구글 액세스 토큰을 찾을 수 없습니다.');
        navigate('/signin');
        return;
      }

      try {
        // 구글의 userinfo 엔드포인트 호출하여 사용자 정보 가져오기
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('구글에서 사용자 정보를 가져오지 못했습니다.');
        }

        const userInfo = await response.json();
        const { email, name } = userInfo; // 필요한 정보: 이메일, 이름 등

        // Cognito에 동일 이메일로 가입된 회원이 있는지 확인
        const exists = await checkUserExists(email);

        if (exists) {
          try {
            const signInResult = await signIn(email, googleDefaultPassword);
            const currentUser = getCurrentUser();
            setUser(currentUser);
            setSession(signInResult);
            navigate('/');
          } catch (loginError) {
            console.error('자동 로그인 실패:', loginError);
            alert('자동 로그인이 실패했습니다. 기존 가입한 경로로 로그인해주세요.');
            navigate('/signin');
            return;
          }
        } else {
          // 신규 회원가입 처리 후 자동 로그인
          await signUp(email, googleDefaultPassword, name || '', '', '');
          console.log('신규 회원가입 처리 후 자동 로그인');
          navigate('/confirm-signup', { state: { email } });
        }
      } catch (error) {
        console.error('구글 로그인 처리 중 오류 발생:', error);   
        navigate('/signin');
      }
    };

    handleGoogleLogin();
  }, [navigate, setUser, setSession, googleDefaultPassword]);

  return <div></div>;
};

export default GoogleCallback;
