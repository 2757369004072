import { useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PlanSetting = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const plans = [
    {
      name: 'Basic Plan',
      features: ['최대 2개 컨텐츠 대여가능', '컨텐츠 반납 후, 무한 대여가능'],
      price: '9,900원(일 330원)',
    },
    {
      name: 'Standard Plan',
      features: ['최대 3개 컨텐츠 대여가능', '컨텐츠 반납 후, 무한 대여가능'],
      price: '14,900원(일 500원)',
      originalPrice: '18,000원',
    },
    {
      name: 'Advanced Plan',
      features: ['최대 4개 컨텐츠 대여가능', '컨텐츠 반납 후, 무한 대여가능'],
      price: '18,900원(일 630원)',
      originalPrice: '24,000원',
    },
  ];

  const currentPlanName = 'Basic Plan'; 

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '55px',
      }}
    >
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            내 프리미엄 멤버십
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          현재 이용중인 플랜
        </Typography>
        {plans
          .filter((plan) => plan.name === currentPlanName)
          .map((plan) => (
            <Card variant="outlined" sx={{ marginBottom: 2 }} key={plan.name}>
              <CardContent>
                <Typography variant="h6">{plan.name}</Typography>
                {plan.features.map((feature, index) => (
                  <Typography variant="body2" key={index}>
                    {feature}
                  </Typography>
                ))}
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {plan.price}
                </Typography>
              </CardContent>
            </Card>
          ))}

        <Typography variant="h6" gutterBottom>
          그 외 플랜
        </Typography>
        {plans
          .filter((plan) => plan.name !== currentPlanName)
          .map((plan) => (
            <Card variant="outlined" sx={{ marginBottom: 2 }} key={plan.name}>
              <CardContent>
                <Typography variant="h6">{plan.name}</Typography>
                {plan.features.map((feature, index) => (
                  <Typography variant="body2" key={index}>
                    {feature}
                  </Typography>
                ))}
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {plan.originalPrice && (
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ textDecoration: 'line-through', marginRight: 1 }}
                    >
                      {plan.originalPrice}
                    </Typography>
                  )}
                  {plan.price}
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" fullWidth>
                  이 플랜으로 변경
                </Button>
              </CardActions>
            </Card>
          ))}
      </Box>
    </Box>
  );
};

export default PlanSetting;

