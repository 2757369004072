import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Home from './pages/Home';
// import Home2 from './pages/Home2';
import Plan from './pages/Plan';
// import Dream from './pages/Dream';
import Dream2 from './pages/Dream2';
import Profile from './pages/Profile';
import Setting from './pages/Setting';
import ProfileEditPage from './pages/setting/ProfileEditPage'; 
import PlanSetting from './pages/setting/PlanSetting';
import AppVersion from './pages/setting/AppVersion';
import AppSetting from './pages/setting/AppSetting';
import Partnership from './pages/setting/Partnership';
import CustomerService from './pages/setting/CustomerService';
import Faq from './pages/setting/Faq';
import HomePlan from './pages/home/HomePlan'
import PlanDetail from './pages/plan/PlanDetail';
import Day from './pages/plan/Day';
import Listening from './pages/plan/Listening';
import Speaking from './pages/plan/Speaking';
import Writing from './pages/plan/Writing';
import PlanEnd from './pages/plan/PlanEnd';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ConfirmSignUp from './pages/auth/ConfirmSignUp';
import ForgotPassword from './pages/auth/ForgotPassword';
import FindAccount from './pages/auth/FindAccount';
import Captcha from './pages/auth/Captcha';
import { AuthProvider, AuthContext } from './pages/auth/AuthContext';
import GoogleCallback from './pages/auth/GoogleCallback';
import LineCallback from './pages/auth/LineCallback';
import KakaoCallback from './pages/auth/KakaoCallback';
import AppleCallback from './pages/auth/AppleCallback';
import Payment from './pages/Payment';
import Payment2 from './pages/Payment2';
import { useDeepLinking } from './useDeepLinking';
import notificationService from './services/NotificationService';

// PrivateRoute component to protect routes
const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user } = useContext(AuthContext);
  return user ? children : <Navigate to="/signin" replace />;
};

const App: React.FC = () => {

  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
};

const AppRoutes: React.FC = () => {
  // 이제 Router 컨텍스트 내에서 useDeepLinking 호출
  useDeepLinking();
  const initNotifications = async () => {
    await notificationService.initialize();
  };
  
  initNotifications();

  return (
    <Routes>
          {/* Public Routes */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirm-signup" element={<ConfirmSignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/find-account" element={<FindAccount />} />
          <Route path="/captcha" element={<Captcha />} />
          <Route path="/google-callback" element={<GoogleCallback />} />
          <Route path="/line-callback" element={<LineCallback />} />
          <Route path="/kakao-callback" element={<KakaoCallback />} />
          <Route path="/apple-callback" element={<AppleCallback />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment2" element={<Payment2 />} />

          {/* Protected Routes */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <ProtectedRoutes />
              </PrivateRoute>
            }
          />
        </Routes>
  );
};

// Separate component for all protected routes
const ProtectedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePlan />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/dream" element={<Dream2 />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/profile/edit" element={<ProfileEditPage />} />
      <Route path="/profile/plan-setting" element={<PlanSetting />} />
      <Route path="/profile/app-version" element={<AppVersion />} />
      <Route path="/profile/app-setting" element={<AppSetting />} />
      <Route path="/profile/partnership" element={<Partnership />} />
      <Route path="/profile/faq" element={<Faq />} />
      <Route path="/profile/customer-service" element={<CustomerService />} />
      <Route path="/home-plan" element={<HomePlan />} />
      <Route path="/plan/plan-detail" element={<PlanDetail />} />
      <Route path="/plan/day" element={<Day />} />
      <Route path="/plan/listening/:dayId" element={<Listening />} />
      <Route path="/plan/speaking/:dayId" element={<Speaking />} />
      <Route path="/plan/writing/:dayId" element={<Writing />} />
      <Route path="/plan/end/:dayId" element={<PlanEnd />} />
      {/* Redirect any unknown routes to home or a 404 page */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
