// FindAccount.tsx
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// (1) react-phone-number-input 라이브러리
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const FindAccount: React.FC = () => {
  const [step, setStep] = useState<'id' | 'password'>('id');
  const navigate = useNavigate();

  // Twilio/Lambda 관련 값들
  const TWILIO_ACCOUNT_SID = process.env.REACT_APP_TWILIO_ACCOUNT_SID || '';
  const TWILIO_AUTH_TOKEN  = process.env.REACT_APP_TWILIO_AUTH_TOKEN  || '';
  const TWILIO_SERVICE_SID = process.env.REACT_APP_TWILIO_SERVICE_SID || '';

  // 전화번호 조회용 Lambda (Cognito 등록 여부 판단)
  const LAMBDA_API_URL = 'https://7yseigv3nc.execute-api.ap-northeast-2.amazonaws.com/find-id';

  // ---------------------------
  // 상태값
  // ---------------------------
  // (1) 국가/전화번호 합쳐진 값 (ex: +821012345678)
  const [phoneValue, setPhoneValue] = useState<string | undefined>();

  // (2) 인증번호
  const [verificationCode, setVerificationCode] = useState('');


  const [isLoading, setIsLoading] = useState(false);

  // 결과 출력
  const [foundEmail, setFoundEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // -------------------------------------------------------------
  // 1) 인증번호 보내기
  // -------------------------------------------------------------
  const handleSendCode = async () => {
    try {
      setError(null);
      setFoundEmail(null);

      if (!phoneValue) {
        setError('전화번호를 입력해주세요.');
        return;
      }

      if (!isValidPhoneNumber(phoneValue)) {
        setError('유효하지 않은 전화번호입니다.');
        return;
      }

      // (1) Cognito로 전화번호 등록 계정 확인
      const checkRes = await fetch(LAMBDA_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: phoneValue }),
      });
      const checkData = await checkRes.json();

      if (!checkRes.ok) {
        throw new Error(checkData.message || '가입된 계정을 찾을 수 없습니다.');
      }

      // (2) Twilio Verify API로 인증번호 발송
      const verifyUrl = `https://verify.twilio.com/v2/Services/${TWILIO_SERVICE_SID}/Verifications`;
      const basicAuth = btoa(`${TWILIO_ACCOUNT_SID}:${TWILIO_AUTH_TOKEN}`);

      const body = new URLSearchParams();
      body.append('To', phoneValue);
      body.append('Channel', 'sms');

      const res = await fetch(verifyUrl, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${basicAuth}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      });

      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.message || '인증번호 전송 실패');
      }

    } catch (err: any) {
      setError(err.message);
    }
  };

  // -------------------------------------------------------------
  // 2) 인증번호 검증 + Cognito 조회(아이디 얻기)
  // -------------------------------------------------------------
  const handleVerifyCode = async () => {
    try {
      setError(null);
      setFoundEmail(null);

      if (!verificationCode.trim()) {
        setError('인증번호를 입력해주세요.');
        return;
      }
      if (!phoneValue) {
        setError('전화번호가 없습니다. 다시 입력해주세요.');
        return;
      }

      // (a) Verify API 호출 (코드 검증)
      const verifyUrl = `https://verify.twilio.com/v2/Services/${TWILIO_SERVICE_SID}/VerificationCheck`;
      const basicAuth = btoa(`${TWILIO_ACCOUNT_SID}:${TWILIO_AUTH_TOKEN}`);

      const body = new URLSearchParams();
      body.append('To', phoneValue);
      body.append('Code', verificationCode.trim());

      const verifyRes = await fetch(verifyUrl, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${basicAuth}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      });
      if (!verifyRes.ok) {
        const errData = await verifyRes.json();
        throw new Error(errData.message || '인증번호 확인 실패');
      }
      const verifyData = await verifyRes.json();
      if (verifyData.status !== 'approved') {
        throw new Error('인증번호가 일치하지 않습니다.');
      }

      // 인증 성공
      setIsLoading(true);

      // (b) Lambda API 호출 → 아이디(이메일) 조회
      const lambdaRes = await fetch(LAMBDA_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: phoneValue }),
      });
      const lambdaData = await lambdaRes.json();
      if (!lambdaRes.ok) {
        throw new Error(lambdaData.message || '아이디(이메일) 조회 실패');
      }

      setFoundEmail(lambdaData.email);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {/* 상단 네비게이션 영역 (← 아이콘 + 탭) */}
      <div style={styles.navBar}>
        <button style={styles.backButton} onClick={() => navigate(-1)}>
          ←
        </button>
        <div style={styles.tabContainer}>
          <button
            style={step === 'id' ? styles.activeTab : styles.inactiveTab}
            onClick={() => setStep('id')}
          >
            아이디 찾기
          </button>
          <button
            style={step === 'password' ? styles.activeTab : styles.inactiveTab}
            onClick={() => setStep('password')}
          >
            비밀번호 찾기
          </button>
        </div>
      </div>

      {/* 메인 컨텐츠 */}
      <div style={styles.content}>
        {step === 'id' && (
          <>
            {/* 휴대폰 번호 입력 + 인증번호 전송 버튼 */}
            <div style={styles.label}>휴대폰 번호 입력 ('-' 제외 입력)</div>
            <div style={styles.inputRow}>
              <div style={{ flex: 1 }}>
                <PhoneInput
                  defaultCountry="KR"
                  placeholder="전화번호 입력 (예: +82 10-0000-0000)"
                  value={phoneValue}
                  onChange={setPhoneValue}
                  international
                  withCountryCallingCode
                  // react-phone-number-input의 기본 CSS가 있으므로, 
                  // 필요하면 아래처럼 override 가능 (예시)
                  // className="custom-phone-input" 
                />
              </div>
              <button style={styles.sendBtn} onClick={handleSendCode}>
                인증번호 전송
              </button>
            </div>

            {/* 인증번호 입력 + 확인 버튼 */}
            <div style={styles.label}>인증번호 입력</div>
            <div style={styles.inputRow}>
              <input
                style={styles.textInput}
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="인증번호 입력"
              />
              <button style={styles.sendBtn} onClick={handleVerifyCode}>
                확인
              </button>
            </div>

            {isLoading && <p style={{ color: 'blue' }}>확인 중...</p>}
            {foundEmail && (
              <p style={{ marginTop: '1rem', color: 'green' }}>
                가입된 이메일: <strong>{foundEmail}</strong>
              </p>
            )}
            {error && (
              <p style={{ marginTop: '1rem', color: 'red' }}>{error}</p>
            )}
          </>
        )}

        {step === 'password' && (
          <>
            <div style={{ marginBottom: '1rem' }}>
              비밀번호를 분실하셨다면, 아래 버튼을 눌러 재설정 페이지로 이동하세요.
            </div>
            <button
              style={styles.sendBtn}
              onClick={() => navigate('/forgot-password')}
            >
              비밀번호 재설정
            </button>
          </>
        )}

        <Link
          to="/signin"
          style={{ display: 'block', marginTop: '1.5rem', textAlign: 'center' }}
        >
          로그인 화면으로 돌아가기
        </Link>
      </div>
    </div>
  );
};

export default FindAccount;

/**
 * 아래 style 객체들은 첨부된 이미지를 참고하여
 * 대략적인 배치/색상만 맞춰 놓은 예시입니다.
 * 필요에 따라 수정하세요.
 */
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '100%',
    maxWidth: '420px',
    margin: '0 auto',
    border: '1px solid #eee',
    borderRadius: '8px',
    minHeight: '600px',
    fontFamily: 'sans-serif',
    backgroundColor: '#fff',
    position: 'relative',
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    borderBottom: '1px solid #eee',
  },
  backButton: {
    background: 'none',
    border: 'none',
    fontSize: '1.2rem',
    marginRight: '1rem',
    cursor: 'pointer',
  },
  tabContainer: {
    display: 'flex',
    flex: 1,
  },
  activeTab: {
    flex: 1,
    padding: '0.5rem 0',
    borderBottom: '2px solid #d3a264',
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#d3a264',
    cursor: 'pointer',
  },
  inactiveTab: {
    flex: 1,
    padding: '0.5rem 0',
    borderBottom: '1px solid #ccc',
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    backgroundColor: 'transparent',
    color: '#888',
    cursor: 'pointer',
  },
  content: {
    padding: '1rem',
  },
  label: {
    fontSize: '0.9rem',
    margin: '1rem 0 0.4rem 0',
    color: '#333',
  },
  inputRow: {
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center',
  },
  textInput: {
    flex: 1,
    padding: '0.5rem',
    fontSize: '1rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginRight: '0.5rem',
  },
  sendBtn: {
    padding: '0.5rem 1rem',
    backgroundColor: '#d3a264',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  findIdButton: {
    width: '100%',
    padding: '0.75rem',
    margin: '1rem 0 0 0',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#d3a264',
    color: '#fff',
    fontSize: '1rem',
    cursor: 'pointer',
  },
};
