import React, { useEffect, useRef, useContext, useState, FormEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserExists, checkUserExistsBySub } from '../../AuthService/cognitoAdmin';
import { signUp, signIn, getCurrentUser } from '../../AuthService/AuthService';
import { AuthContext } from './AuthContext';

interface PendingUserData {
  sub: string;
  name: string;
}

const LineCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setUser, setSession } = useContext(AuthContext);
  const lineDefaultPassword = "LineSignInPassword1!"; // LINE 전용 기본 비밀번호

  // useRef를 사용하여 useEffect의 중복 실행을 방지합니다.
  const effectRan = useRef(false);

  // 모달 관련 상태
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [manualEmail, setManualEmail] = useState("");
  const [pendingUserData, setPendingUserData] = useState<PendingUserData | null>(null);

  // processUser 함수를 useCallback으로 감싸서 메모이제이션합니다.
  const processUser = useCallback(
    async (email: string, sub: string, name: string) => {
      try {
        // Lambda를 호출하여 동일한 sub로 가입된 사용자가 있는지 확인합니다.
        // checkUserExistsBySub는 존재하면 해당 사용자의 이메일을, 없으면 false를 반환합니다.
        const subResult = await checkUserExistsBySub(sub);
        // Lambda를 호출하여 동일한 이메일로 가입된 사용자가 있는지 확인합니다.
        const existsByEmail = await checkUserExists(email);
        console.log(email, existsByEmail, subResult);

        if (subResult !== false) {
          // 이미 sub로 가입된 사용자가 있다면, subResult(이미 가입된 회원의 이메일)를 사용하여 자동 로그인 시도
          try {
            const signInResult = await signIn(subResult, lineDefaultPassword);
            const currentUser = getCurrentUser();
            setUser(currentUser);
            setSession(signInResult);
            navigate('/');
          } catch (loginError) {
            console.error('자동 로그인 실패:', loginError);
            alert('자동 로그인이 실패했습니다. 기존 가입한 경로로 로그인해주세요.');
            navigate('/signin');
          }
        } else {
          if (existsByEmail) {
            // 동일한 이메일로 가입된 사용자가 있다면 에러 처리
            alert('이미 동일한 이메일로 가입된 회원이 있습니다.');
            navigate('/signin');
          } else {
            // 신규 회원이면 회원가입 후 확인 페이지로 이동 (추가 정보 입력 등)
            await signUp(email, lineDefaultPassword, name, '', '', sub);
            console.log('신규 회원가입 처리 후 자동 로그인');
            navigate('/confirm-signup', { state: { email } });
          }
        }
      } catch (error) {
        console.error('사용자 처리 중 오류 발생:', error);
        alert(`사용자 처리 중 오류 발생: ${error}`);
        navigate('/signin');
      }
    },
    [navigate, setUser, setSession, lineDefaultPassword]
  );

  // 모달 제출 핸들러
  const handleEmailSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!manualEmail.trim() || !pendingUserData) return;
    await processUser(manualEmail.trim(), pendingUserData.sub, pendingUserData.name);
  };

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const handleLineLogin = async () => {
      // URL 쿼리 파라미터에서 'code' 추출 (예: ?code=...&state=...)
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');

      if (!code) {
        alert('라인 인증 코드를 찾을 수 없습니다.');
        navigate('/signin');
        return;
      }

      try {
        // LINE 토큰 엔드포인트에 POST 요청하여 토큰 교환을 진행합니다.
        const tokenResponse = await fetch('https://api.line.me/oauth2/v2.1/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            code,
            redirect_uri: window.location.origin + '/line-callback',
            client_id: process.env.REACT_APP_LINE_CLIENT_ID || '',
            client_secret: process.env.REACT_APP_LINE_CLIENT_SECRET || '',
          }).toString(),
        });

        if (!tokenResponse.ok) {
          const errorData = await tokenResponse.json();
          throw new Error(`라인 토큰 교환 실패: ${errorData.error_description || errorData.error}`);
        }

        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;
        if (!accessToken) {
          throw new Error('라인 액세스 토큰이 없습니다.');
        }

        // id_token이 있는지 확인합니다.
        if (!tokenData.id_token) {
          throw new Error('id_token이 없습니다.');
        }

        // id_token 검증 API 호출 (https://api.line.me/oauth2/v2.1/verify)
        const verifyResponse = await fetch('https://api.line.me/oauth2/v2.1/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            id_token: tokenData.id_token,
            client_id: process.env.REACT_APP_LINE_CLIENT_ID || ''
          }).toString(),
        });

        if (!verifyResponse.ok) {
          const errorVerify = await verifyResponse.json();
          throw new Error(`id_token 검증 실패: ${errorVerify.error_description || errorVerify.error}`);
        }

        const verifiedPayload = await verifyResponse.json();

        // 검증된 payload에서 sub, 이메일, 이름 추출
        const sub = verifiedPayload.sub;
        const payloadEmail = verifiedPayload.email; // payload에 포함된 이메일 (없을 수도 있음)
        const name = verifiedPayload.name || '';

        console.log('라인 사용자 정보 (검증 후):', verifiedPayload);

        // sub 값이 없으면 에러 처리
        if (!sub) {
          alert('LINE 계정에 사용자 식별자(sub) 정보가 없습니다.');
          navigate('/signin');
          return;
        }

        // 만약 payload에 이메일 정보가 없다면 먼저 checkUserExistsBySub 함수를 호출하여 이메일이 등록되어 있는지 확인
        if (!payloadEmail) {
          const subResult = await checkUserExistsBySub(sub);
          if (subResult !== false) {
            // 이미 가입된 사용자의 이메일이 반환되면 그걸로 자동 로그인
            await processUser(subResult, sub, name);
            return;
          } else {
            // 이메일 정보가 없고, 가입된 이메일도 없으면 모달을 띄워 사용자가 직접 입력하도록 함.
            setPendingUserData({ sub, name });
            setShowEmailModal(true);
            return;
          }
        }

        // 이메일 정보가 payload에 존재하면 바로 가입 여부 체크 및 로그인/회원가입 처리 진행
        await processUser(payloadEmail, sub, name);

      } catch (error) {
        console.error('라인 로그인 처리 중 오류 발생:', error);
        alert(`라인 로그인 처리 중 오류 발생: ${error}`);
        navigate('/signin');
      }
    };

    handleLineLogin();
  }, [navigate, setUser, setSession, lineDefaultPassword, processUser]);

  return (
    <div>
      <div></div>

      {showEmailModal && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle}>
            <h2>이메일 입력</h2>
            <p>LINE 계정에 이메일 정보가 없어 직접 입력해 주세요.</p>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                placeholder="이메일 입력"
                value={manualEmail}
                onChange={(e) => setManualEmail(e.target.value)}
                required
                style={{ padding: '8px', width: '100%', marginBottom: '12px' }}
              />
              <button type="submit" style={buttonStyle}>확인</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// 간단한 모달 스타일 (원하는 대로 수정 가능)
const modalOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const modalStyle: React.CSSProperties = {
  backgroundColor: '#fff',
  padding: '24px',
  borderRadius: '8px',
  width: '90%',
  maxWidth: '400px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
};

const buttonStyle: React.CSSProperties = {
  padding: '10px 20px',
  backgroundColor: '#007BFF',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default LineCallback;
