import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';
import { getUserAttributes } from '../AuthService/AuthService';

class NotificationService {
  constructor() {
    this.pushEnabled = false;
    this.doNotDisturbEnabled = false;
    this.dndStartTime = '00:00';
    this.dndEndTime = '23:59';
    this.initialized = false;
    this.isNativePlatform = Capacitor.isNativePlatform();
    this.unreadNotifications = 0;  // Add tracking for unread notifications
    this.hasLoadedUnreadCount = false;  // Track if we've loaded the count
  }

  // Initialize the notification service
  async initialize() {
    // 이미 초기화되었다면 바로 리턴
    if (this.initialized) {
      return true;
    }
    
    // 웹 플랫폼일 경우 알림 기능 초기화 건너뛰기
    if (!this.isNativePlatform) {
      console.log('Web platform detected - push notifications not available');
      this.initialized = true;
      return true;
    }
    
    try {
      // Request permission
      const permStatus = await PushNotifications.requestPermissions();
      
      if (permStatus.receive === 'granted') {
        // Register with Apple Push Notification service / Firebase Cloud Messaging
        await PushNotifications.register();
        
        // Initialize push notification listeners
        this.initListeners();
        
        // Load user notification settings
        await this.loadNotificationSettings();
        
        // Load unread notifications count
        await this.loadUnreadNotificationsCount();
        
        // Schedule the daily notification at system level
        if (this.pushEnabled) {
          await this.scheduleDailyNotification();
        }
        
        this.initialized = true;
        return true;
      } else {
        console.log('Push notification permission was denied');
        this.initialized = true;
        return false;
      }
    } catch (error) {
      console.log('Error initializing notifications:', error);
      this.initialized = true;
      return false;
    }
  }

  // Initialize push notification listeners
  initListeners() {
    // 웹 플랫폼에서는 리스너 등록 건너뛰기
    if (!this.isNativePlatform) return;
    
    // Registration success event
    PushNotifications.addListener('registration', (token) => {
      console.log('Push registration success, token: ' + token.value);
      // Here you would typically send this token to your server
    });

    // Registration error event
    PushNotifications.addListener('registrationError', (error) => {
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    // Push notification received event
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push notification received: ' + JSON.stringify(notification));
      
      // 방해금지 시간인지 확인하고, 방해금지 시간이면 알림을 표시하지 않음
      if (this.doNotDisturbEnabled && this.isDoNotDisturbTime()) {
        console.log('Do Not Disturb is active, suppressing notification');
        return; // 알림 처리 중단
      }
      
      // Increment unread count when notification is received
      this.incrementUnreadCount();
      
      // 방해금지 시간이 아니면 알림 처리 계속
      // 알림 표시 로직은 여기에 추가
    });

    // Push notification action performed event
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push notification action performed: ' + JSON.stringify(notification));
    });
  }

  // Load user notification settings from the auth service
  async loadNotificationSettings() {
    try {
      const attributes = await getUserAttributes();
      
      // Load all settings from the single appSetting JSON attribute
      const appSettingsStr = attributes['custom:appSetting'];
      
      if (appSettingsStr) {
        const appSettings = JSON.parse(appSettingsStr);
        
        // Extract notification-related settings from the parsed object
        this.pushEnabled = appSettings.pushNotifications === true;
        this.doNotDisturbEnabled = appSettings.doNotDisturb === true;
        this.dndStartTime = appSettings.dndStartTime || '00:00';
        this.dndEndTime = appSettings.dndEndTime || '23:59';
      } else {
        // Use default values if no settings exist
        this.pushEnabled = false;
        this.doNotDisturbEnabled = false;
        this.dndStartTime = '00:00';
        this.dndEndTime = '23:59';
      }
      
      console.log('Notification settings loaded:', {
        pushEnabled: this.pushEnabled,
        doNotDisturbEnabled: this.doNotDisturbEnabled,
        dndStartTime: this.dndStartTime,
        dndEndTime: this.dndEndTime
      });
    } catch (error) {
      console.error('Failed to load notification settings:', error);
    }
  }

  // Load unread notifications count from user attributes
  async loadUnreadNotificationsCount() {
    if (this.hasLoadedUnreadCount) return this.unreadNotifications;
    
    try {
      const attributes = await getUserAttributes();
      const appSettingsStr = attributes['custom:appSetting'];
      
      if (appSettingsStr) {
        const appSettings = JSON.parse(appSettingsStr);
        this.unreadNotifications = appSettings.unreadNotifications || 0;
      } else {
        this.unreadNotifications = 0;
      }
      
      this.hasLoadedUnreadCount = true;
      return this.unreadNotifications;
    } catch (error) {
      console.error('Failed to load unread notifications count:', error);
      return 0;
    }
  }

  // Increment unread notifications count
  async incrementUnreadCount() {
    await this.loadUnreadNotificationsCount();
    this.unreadNotifications += 1;
    
    // Here you would update the user attributes with the new count
    // This is a simplified example - you would need to implement
    // the actual attribute updating logic based on your app's needs
    try {
      const attributes = await getUserAttributes();
      const appSettingsStr = attributes['custom:appSetting'];
      let appSettings = {};
      
      if (appSettingsStr) {
        appSettings = JSON.parse(appSettingsStr);
      }
      
      appSettings.unreadNotifications = this.unreadNotifications;
      
      // Here you would save the updated appSettings back to the user's attributes
      // This depends on your specific implementation for updating user attributes
      
      return this.unreadNotifications;
    } catch (error) {
      console.error('Failed to save unread notifications count:', error);
      return this.unreadNotifications;
    }
  }

  // Check if there are unread notifications
  async hasUnreadNotifications() {
    await this.loadUnreadNotificationsCount();
    return this.unreadNotifications > 0;
  }

  // Mark all notifications as read
  async markAllAsRead() {
    this.unreadNotifications = 0;
    this.hasLoadedUnreadCount = true;
    
    // Here you would update the user attributes to save this change
    try {
      const attributes = await getUserAttributes();
      const appSettingsStr = attributes['custom:appSetting'];
      let appSettings = {};
      
      if (appSettingsStr) {
        appSettings = JSON.parse(appSettingsStr);
      }
      
      appSettings.unreadNotifications = 0;
      
      // Here you would save the updated appSettings back to the user's attributes
      // This depends on your specific implementation for updating user attributes
      
      return this.unreadNotifications;
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
      return this.unreadNotifications;
    }
  }

  // Get the count of unread notifications
  async getUnreadCount() {
    await this.loadUnreadNotificationsCount();
    return this.unreadNotifications;
  }

  // Check if it's currently do not disturb time
  isDoNotDisturbTime() {
    if (!this.doNotDisturbEnabled) {
      return false;
    }
    
    const now = new Date();
    const currentTime = now.getHours().toString().padStart(2, '0') + ':' + 
                        now.getMinutes().toString().padStart(2, '0');
    
    // Handle the case where DND spans across midnight
    if (this.dndStartTime <= this.dndEndTime) {
      return currentTime >= this.dndStartTime && currentTime <= this.dndEndTime;
    } else {
      return currentTime >= this.dndStartTime || currentTime <= this.dndEndTime;
    }
  }

  // 지정된 시간이 방해금지 시간에 해당하는지 확인
  isTimeInDoNotDisturbPeriod(hours, minutes) {
    if (!this.doNotDisturbEnabled) {
      return false;
    }
    
    const timeToCheck = hours.toString().padStart(2, '0') + ':' + 
                        minutes.toString().padStart(2, '0');
    
    // Handle the case where DND spans across midnight
    if (this.dndStartTime <= this.dndEndTime) {
      return timeToCheck >= this.dndStartTime && timeToCheck <= this.dndEndTime;
    } else {
      return timeToCheck >= this.dndStartTime || timeToCheck <= this.dndEndTime;
    }
  }

  // Schedule the daily notification at system level, respecting DND settings
  async scheduleDailyNotification() {
    // 웹 플랫폼이거나 알림이 비활성화된 경우 스케줄러 시작 건너뛰기
    if (!this.isNativePlatform || !this.pushEnabled) {
      console.log('Skipping notification scheduling: platform or settings not compatible');
      return;
    }

    try {
      // Cancel any existing scheduled notifications
      const pendingNotifications = await LocalNotifications.getPending();
      if (pendingNotifications.notifications.length > 0) {
        await LocalNotifications.cancel(pendingNotifications);
      }
      
      // 기본 알림 시간 설정 (10 PM)
      let notificationHour = 22;
      let notificationMinute = 0;
      
      // 방해금지 모드가 활성화된 경우, 알림 예약 시간이 방해금지 시간대에 해당하는지 확인
      if (this.doNotDisturbEnabled && this.isTimeInDoNotDisturbPeriod(notificationHour, notificationMinute)) {
        console.log('Default notification time (10 PM) falls within Do Not Disturb period, adjusting...');
        
        // 방해금지 종료 시간 파싱
        const [endHours, endMinutes] = this.dndEndTime.split(':').map(num => parseInt(num));
        
        // 방해금지 종료 후 5분 뒤로 알림 설정
        notificationHour = endHours;
        notificationMinute = endMinutes + 5;
        
        // 분이 60을 넘어가면 시간 조정
        if (notificationMinute >= 60) {
          notificationHour = (notificationHour + 1) % 24;
          notificationMinute = notificationMinute - 60;
        }
        
        console.log(`Adjusted notification time to ${notificationHour}:${notificationMinute} (after DND period)`);
      }
      
      // Schedule new notification at system level (will persist even if app is closed)
      await LocalNotifications.schedule({
        notifications: [
          {
            title: '일일 목표 알림',
            body: '하루가 끝나기 전, 목표를 달성해보세요!',
            id: 1,
            schedule: { 
              every: 'day',
              on: { hour: notificationHour, minute: notificationMinute }
            }
          }
        ]
      });
      
      console.log(`Daily notification scheduled for ${notificationHour}:${notificationMinute} every day`);
    } catch (error) {
      console.error('Failed to schedule daily notification:', error);
    }
  }
  
  // Send a local notification immediately if not in DND time
  async sendImmediateNotification(title, body) {
    // 웹 플랫폼이거나 알림이 비활성화된 경우 알림 발송 건너뛰기
    if (!this.isNativePlatform || !this.pushEnabled) {
      console.log('Skipping immediate notification: platform or settings not compatible');
      return;
    }
    
    // 방해금지 시간인지 확인
    if (this.doNotDisturbEnabled && this.isDoNotDisturbTime()) {
      console.log('Do Not Disturb is active, suppressing immediate notification');
      return;
    }
    
    try {
      await LocalNotifications.schedule({
        notifications: [
          {
            title,
            body,
            id: Date.now(), // 고유 ID 생성
          }
        ]
      });
      
      // Increment unread count when a notification is sent
      await this.incrementUnreadCount();
      
      console.log('Immediate notification sent');
    } catch (error) {
      console.error('Failed to send immediate notification:', error);
    }
  }

  // Update notification settings when they change in the app
  async updateSettings(pushEnabled, doNotDisturbEnabled, dndStartTime, dndEndTime) {
    this.pushEnabled = pushEnabled;
    this.doNotDisturbEnabled = doNotDisturbEnabled;
    this.dndStartTime = dndStartTime;
    this.dndEndTime = dndEndTime;
    
    // 웹 플랫폼에서는 알림 관리 건너뛰기
    if (!this.isNativePlatform) {
      return;
    }
    
    // Re-schedule notifications based on new settings
    if (pushEnabled) {
      await this.scheduleDailyNotification();
    } else {
      // If notifications are disabled, cancel any pending notifications
      try {
        const pendingNotifications = await LocalNotifications.getPending();
        if (pendingNotifications.notifications.length > 0) {
          await LocalNotifications.cancel(pendingNotifications);
          console.log('Notifications cancelled due to disabled setting');
        }
      } catch (error) {
        console.error('Failed to cancel notifications:', error);
      }
    }
  }
}

// Create and export a singleton instance
const notificationService = new NotificationService();
export default notificationService;